@let page = transferRequestItemGroupPage() ;
@let transferRequestItemsByArticleList = page?.content || [] ;

<p-table #dt
         [value]="transferRequestItemsByArticleList"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="page?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoadEvent($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         selectionMode="single"
         [multiSortMeta]="[{ field: 'ARTICLE', order: 1 }, { field: 'RECEPTION', order: 1 }]"
>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th i18n>
      </th>
      <th i18n>
      </th>
      <th i18n>
      </th>
      <th>
      </th>
      <th colspan="2">
        🏬 Stock
      </th>
      <th>
        Total
      </th>
      <th [colSpan]="targetWarehouses()?.length">
        Warehouses
      </th>
    </tr>
    <tr style="vertical-align: bottom">
      <th i18n>
        Article
        <p-sortIcon field="DISPATCH_DATE"/>
      </th>
      <th i18n>
        Reception
      </th>
      <th i18n>
        Deliver before
      </th>
      <th>
        Full description
      </th>
      <th>
        Pallets
      </th>
      <th>
        🏋️
      </th>
      <th>
        Total
      </th>
      @for (targetWarehouse of targetWarehouses(); track targetWarehouse?.id) {
        <th>
          <foodbank-warehouse ngDefaultControl [ngModel]="targetWarehouse" [showDetails]="false" [showId]="true" [showCompany]="true"/>
        </th>
      }
    </tr>
    <tr>
      <th>
        <p-columnFilter field="article" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter">
            <foodbank-article-selection ngDefaultControl [(ngModel)]="articleFilter" [articleSearch]="articleSearch()"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
      </th>
      <th>
        <p-columnFilter field="exactDeliverBeforeDate" [showMenu]="false" style="width: 10em; display: inline-block">
          <ng-template pTemplate="filter">
            <foodbank-date-picker
              ngDefaultControl
              [ngModel]="exactDeliverBeforeDateFilter()"
              (onSelect)="handleExactDeliverBeforeDateSelection($event.value)"
              [showSelectionOptions]="false"
              [required]="false"
              [showButtonBar]="true"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter [showMenu]="false">
          <ng-template pTemplate="filter">
            <input ngDefaultControl pInputText [(ngModel)]="fullDescriptionFilter"/>
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
      </th>
      <th>
      </th>
      <th>
      </th>
      @for (targetWarehouse of targetWarehouses(); track targetWarehouse?.id) {
        <th>
        </th>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row>
    @let transferRequestItemGroup = typing(row) ;
    @if (transferRequestItemGroup && transferRequestItemGroup.article()) {
      <tr>
        <td>
          <foodbank-article ngDefaultControl [ngModel]="transferRequestItemGroup.article()"/>
        </td>
        <td>
          {{ transferRequestItemGroup.reception()?.id }}
        </td>
        <td>
          {{ transferRequestItemGroup.deliverBeforeDate | date: 'dd/MM/yyyy' }}
        </td>
        <td>
          {{ transferRequestItemGroup.fullDescription }}
        </td>
        <td>
          {{ transferRequestItemGroup.numberOfPallets }}
        </td>
        <td>
          {{ transferRequestItemGroup.totalWeight }}
        </td>
        <td>
          <span
            [class.toomuch]="(transferRequestItemGroup.total() ?? 0) > transferRequestItemGroup.numberOfPallets"
            [class.exact]="transferRequestItemGroup.total()  === transferRequestItemGroup.numberOfPallets"
          >
          {{ transferRequestItemGroup.total() }}
          </span>
        </td>
        @let warehouseTransferRequestItemMap = transferRequestItemGroup.warehouseTransferRequestItemMap() ;
        @for (targetWarehouse of targetWarehouses(); track targetWarehouse.id) {
          <td>
            @let transferRequestItem = warehouseTransferRequestItemMap.get(targetWarehouse.id) ;

            <input pInputText type="number" min="0" [ngModel]="transferRequestItem?.palletQuantity" (ngModelChange)="updatePalletQuantity(transferRequestItemGroup,targetWarehouse,$event)" class="pallet-quantity-edit"/>
          </td>
        }
      </tr>
    } @else {
      <tr>
        <td colspan="100%">
          <span i18n>Loading transfer request item group ...</span>
        </td>
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <span i18n>No transfer requests found.</span>
  </ng-template>
</p-table>
