import {Warehouse} from '@model/warehouse';
import { WarehouseSearchDto} from '@typedefs/stock-rest';
import {Company} from '@model/company';
import {PreparationSearch} from '@model/search/preparation-search';
import {StockSearch} from '@model/search/stock-search';
import {StockPrevisionSearch} from "@model/search/stock-prevision-search";
import {SafeOmit} from "@model/mapping-utils";
import {TransferRequestSearch} from "@model/search/transfer-request-search";

export interface WarehouseSearch extends SafeOmit<WarehouseSearchDto, 'warehouseIds' | 'companyId' | 'preparationSearchDto' | 'stockSearchDto' | 'sourceWarehouseFromStockPrevisionSearchDto' | 'destinationWarehouseFromStockPrevisionSearchDto'> {
  warehouses?: Warehouse[]
  company?: Company,
  preparationSearch?: PreparationSearch,
  stockSearch?: StockSearch,
  sourceStockPrevisionSearch?: StockPrevisionSearch,
  destinationStockPrevisionSearch?: StockPrevisionSearch,
  sourceWarehouseFromTransferRequestSearch?: TransferRequestSearch,
}

export function warehouseSearchByCompany(company: Company | undefined): WarehouseSearch | undefined {
  return company ? { company } : undefined;
}

export function activeWarehouseSearchByCompany(company: Company): WarehouseSearch {
  return {
    ...warehouseSearchByCompany(company),
    active: true
  };
}

export function warehouseSearchByWarehouse(warehouse: Warehouse | undefined): WarehouseSearch {
  return {
    warehouses: warehouse ? [ warehouse ] : undefined
  };
}

export function warehouseSearchByCompanyAndWarehouse(company: Company, warehouse: Warehouse): WarehouseSearch {
  return {
    ...warehouseSearchByCompany(company),
    ...warehouseSearchByWarehouse(warehouse)
  };
}
