import { Component, computed, ElementRef, EventEmitter, input, Input, OnChanges, OnInit, Output, signal, Signal, ViewChild } from '@angular/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {FeadPlanningByOrganization} from '@model/fead-planning-by-organization';
import { Article } from "@model/article";

interface FeadArticlePlanningEntrySummary {
  unitsPerParcel: Signal<number | undefined>;
  unitWeight: Signal<number | undefined>;
  saldo: number;
  total: number;
}

interface FeadArticlePlanningEntryWithSummary extends FeadPlanningByOrganization {
  summary: FeadArticlePlanningEntrySummary;
}

interface FeadArticlePlanningSummary {
  unitsPerParcel: Signal<number | undefined>;
  plannedQuantity: number;
  shippedQuantity: number;
  total: number;
  saldo: number;
  periodQuantityMap: { [index: string]: number };
}

@Component({
  selector: 'foodbank-fead-planning-article-table',
  templateUrl: './fead-planning-article-table.component.html',
  styleUrls: ['./fead-planning-article-table.component.scss']
})
export class FeadPlanningArticleTableComponent implements OnInit, OnChanges {

  article= input<Article>();
  @Input()
  planningData!: FeadPlanningByOrganization[];
  @Input()
  year!: number;
  @Output()
  dataChange = new EventEmitter<FeadPlanningByOrganization[]>();
  @ViewChild('periodNameLabel') periodNameLabel: ElementRef | undefined; // to focus input element, this needs to be set

  addPeriodVisible = false;
  newPeriodName: string | undefined;

  periods: string[] = [];
  entryWithSummaryList: FeadArticlePlanningEntryWithSummary[] = [];
  generalSummary: FeadArticlePlanningSummary | undefined;
  tableSizeStyleClass = localStorage.getItem('FOODBANK_PREFERENCES_STOCK_TABLE_SIZE_OPTION') || '';

  constructor(
    private hotkeysService: HotkeysService,
  ) {
  }

  ngOnInit() {
    const addPeriodHotkey = new Hotkey('+', (event: KeyboardEvent): boolean => {
      this.showNewPeriod();
      return false; // Prevent bubbling
    });
    this.hotkeysService.add(addPeriodHotkey);
  }

  ngOnChanges(): void {
    this.load();
  }

  updateEntryPeriodQuantity(feadArticlePlanningEntryWithSummary: FeadArticlePlanningEntryWithSummary, period: string, quantity: number) {
    feadArticlePlanningEntryWithSummary.periodQuantityMap[period] = quantity;
    feadArticlePlanningEntryWithSummary.summary = this.getSummary(feadArticlePlanningEntryWithSummary);
    this.calcGeneralSummary();
    this.dataChange.emit(this.entryWithSummaryList);
  }

  addPeriod() {
    this.showNewPeriod();
  }

  addSelectedPeriod(newPeriodName: string) {
    if (!this.periods.includes(newPeriodName)) {
      this.periods.push(newPeriodName);
    }
    this.periods = this.periods.sort(this.comparePeriods);
    this.hideNewPeriod();
  }

  cancelAddPeriod() {
    this.hideNewPeriod();
  }

  private load() {
    this.getPeriods();
    this.getSummaries();
    this.calcGeneralSummary();
  }

  updateNewPeriodName(newPeriodName: string) {
    this.newPeriodName = newPeriodName;
  }

  private getPeriods() {
    const rawPeriods = this.planningData
      .map(entry => Object.keys(entry.periodQuantityMap))
      .flat()
      .reduce((periods: string[], period: string) => [...periods, period], [])
      .sort(this.comparePeriods);
    this.periods = Array.from(new Set(rawPeriods));
  }

  private getSummaries() {
    this.entryWithSummaryList = this.planningData.map(entry => this.getFeadArticlePlanningEntrySummary(entry));
  }

  private calcGeneralSummary() {
    this.generalSummary = this.entryWithSummaryList
      .reduce((currentSummary, entryWithSummary) =>
        this.summarizeEntry(currentSummary, entryWithSummary), this.createInitialSummary());
  }

  private createInitialSummary(): FeadArticlePlanningSummary {
    return {
      unitsPerParcel: signal(0),
      plannedQuantity: 0,
      shippedQuantity: 0,
      total: 0,
      saldo: 0,
      periodQuantityMap: {},
    };
  }

  private summarizeEntry(summary: FeadArticlePlanningSummary, entry: FeadArticlePlanningEntryWithSummary): FeadArticlePlanningSummary {
    summary.unitsPerParcel = computed(() => this.article()?.feadUnitsPerParcel)
    summary.plannedQuantity += entry.plannedQuantity;
    summary.shippedQuantity += entry.shippedQuantity;

    summary.total += entry.summary.total;
    summary.saldo += entry.summary.saldo;

    for (const period in entry.periodQuantityMap) {
      let summaryPeriodQuantity = summary.periodQuantityMap[period] || 0;
      summaryPeriodQuantity += entry.periodQuantityMap[period];
      summary.periodQuantityMap[period] = summaryPeriodQuantity;
    }

    return summary;
  }

  private getFeadArticlePlanningEntrySummary(feadArticlePlanningEntry: FeadPlanningByOrganization): FeadArticlePlanningEntryWithSummary {
    return {
      ...feadArticlePlanningEntry,
      summary: this.getSummary(feadArticlePlanningEntry)
    };
  }

  private getSummary(feadArticlePlanningEntry: FeadPlanningByOrganization): FeadArticlePlanningEntrySummary {
    const total = this.getTotal(feadArticlePlanningEntry);
    return {
      unitsPerParcel: computed(() => this.article()?.feadUnitsPerParcel),
      unitWeight: computed(() => this.article()?.feadUnitWeight),
      saldo: feadArticlePlanningEntry.plannedQuantity - total,
      total: total
    }
  }

  private getTotal(feadArticlePlanningEntry: FeadPlanningByOrganization): number {
    const periodQuantityMap = feadArticlePlanningEntry.periodQuantityMap;
    const quantitySum = Object.values(periodQuantityMap)
      .reduce((runningSum, quantity) => runningSum + quantity, 0);
    const shippedQuantity = feadArticlePlanningEntry.shippedQuantity;
    return quantitySum + shippedQuantity;
  }

  private showNewPeriod() {
    this.addPeriodVisible = true;
    this.newPeriodName = this.year + '/';
    setTimeout(() => this.periodNameLabel?.nativeElement?.click(), 0); // set focus to input, this is the only working way I found
  }

  private hideNewPeriod() {
    this.addPeriodVisible = false;
    this.newPeriodName = undefined;
  }

  private comparePeriods(period1: string, period2: string): number {
    // extract year and week from the strings
    const [year1, week1] = period1.split("/");
    const [year2, week2] = period2.split("/");

    // compare years
    const yearComparison = parseInt(year1) - parseInt(year2);

    if (yearComparison!==0) {
      // years are different, done
      return yearComparison;
    }

    // compare weeks
    return parseInt(week1) - parseInt(week2);
  }

  typed(entryWithSummary: FeadArticlePlanningEntryWithSummary): FeadArticlePlanningEntryWithSummary {
    return entryWithSummary;
  }
}
