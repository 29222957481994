import { StockSearchDto } from '@typedefs/stock-rest';
import { WarehouseSearch, warehouseSearchByWarehouse } from '@model/search/warehouse-search';
import { ArticleSearch } from '@model/search/article-search';
import { Warehouse } from '@model/warehouse';
import { Zone } from "@model/zone";
import { Preparation } from "@model/preparation";
import { InternalBatchSearch } from "@model/search/internal-batch-search";
import { PalletSearch } from "@model/search/pallet-search";
import { SafeOmit } from "@model/mapping-utils";

export interface StockSearch extends SafeOmit<StockSearchDto, 'internalBatchSearchDto' | 'supplierBatchSearchDto' | 'warehouseSearchDto' | 'articleSearchDto' | 'stockPalletSearchDto'> {

  warehouseSearch?: WarehouseSearch;
  articleSearch?: ArticleSearch;
  internalBatchSearch?: InternalBatchSearch;
  supplierBatchSearch?: InternalBatchSearch;
  palletSearch?: PalletSearch;
  preparationZone?: Zone
  excludedPreparation?: Preparation

}

export function stockSearchByWarehouse(warehouse: Warehouse | undefined): StockSearch {
  return {
    warehouseSearch: warehouseSearchByWarehouse(warehouse)
  };
}

export function stockSearchByLocationAndWarehouse(location: string, warehouse: Warehouse): StockSearch {
  return {
    location,
    warehouseSearch: warehouseSearchByWarehouse(warehouse)
  };
}

export function stockSearchByPreparationZoneAndWarehouse(zone: Zone, warehouse: Warehouse): StockSearch {
  return {
    preparationZone: zone,
    warehouseSearch: warehouseSearchByWarehouse(warehouse)
  };
}
