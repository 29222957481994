export class Cache<K, V> {

  #data: Map<K, V> = new Map<K, V>();

  public computeIfAbsent(id: K, getter: (id: K) => V): V {
    if (this.#data.has(id)) {
      const value = this.#data.get(id);
      // this will always be true, but we have to do this to make it clear to the type system and not declare the function as possibly returning `undefined`
      if (value != undefined) {
        // console.debug(`Cache hit for id ${id}`);
        return value;
      }
    }
    // console.debug(`Cache miss for id ${id}`);
    const value = getter(id);
    this.#data.set(id, value);
    return value;
  }

}
