import {ChangeDetectionStrategy, Component, computed, inject, Injector, model, Signal, WritableSignal} from '@angular/core';
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {TransferRequest} from "@model/transfer-request";
import {Pagination} from "@services/pagination";
import {Page} from "@typedefs/page";
import {derivedAsync} from "ngxtension/derived-async";
import {CompanyComponent} from "@components/company/company.component";
import {FormsModule} from "@angular/forms";
import {UserComponent} from "@components/users/user.component";
import {CheckboxModule} from "primeng/checkbox";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {CompanySingleSelectionComponent} from "@components/company/selection/single/company-single-selection.component";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {Button} from "primeng/button";
import {Router} from "@angular/router";
import {TransferRequestTargetSearch} from "@model/search/transfer-request-target-search";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {TransferRequestTargetService} from "@services/transfer-request-target.service";
import {AsyncPipe, DatePipe} from "@angular/common";

@Component({
  selector: 'foodbank-transfer-request-target-list',
  templateUrl: './transfer-request-target-list.component.html',
  standalone: true,
  imports: [
    TableModule,
    CompanyComponent,
    FormsModule,
    UserComponent,
    CheckboxModule,
    WarehouseComponent,
    WarehouseSingleSelectionComponent,
    CompanySingleSelectionComponent,
    TriStateCheckboxModule,
    Button,
    DatePipe,
    AsyncPipe,
  ],
  styleUrls: ['./transfer-request-target-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferRequestTargetListComponent {

  transferRequest = model<TransferRequest>();

  pagination: WritableSignal<Pagination>;
  transferRequestTargetSearch: Signal<TransferRequestTargetSearch>;
  transferRequestTargetPage: Signal<Page<TransferRequestTarget> | undefined>;

  #transferRequestTargetService = inject(TransferRequestTargetService);
  #paginationService = inject(PaginationService);

  #injector = inject(Injector);
  #router = inject(Router);

  constructor() {
    this.pagination = this.#paginationService.getDefaultPaginationSignal();
    this.transferRequestTargetSearch = computed(() => ({
      transferRequest: this.transferRequest(),
    }));
    this.transferRequestTargetPage = derivedAsync(() => this.#transferRequestTargetService.findTransferRequestTargets$(this.transferRequestTargetSearch(), this.pagination(), this.#injector));
  }

  handleLazyLoadEvent(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  typing(transferRequestTarget: TransferRequestTarget): TransferRequestTarget {
    return transferRequestTarget;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  protected readonly console = console;
}
