import {ChangeDetectionStrategy, Component, computed, inject, Injector, model, signal, Signal, WritableSignal} from '@angular/core';
import {TableLazyLoadEvent, TableModule} from "primeng/table";
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {TransferRequest} from "@model/transfer-request";
import {Pagination} from "@services/pagination";
import {TransferRequestService} from "@services/transfer-request.service";
import {Page} from "@typedefs/page";
import {derivedAsync} from "ngxtension/derived-async";
import {CompanyComponent} from "@components/company/company.component";
import {FormsModule} from "@angular/forms";
import {UserComponent} from "@components/users/user.component";
import {CheckboxModule} from "primeng/checkbox";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {Company} from "@model/company";
import {Warehouse} from "@model/warehouse";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {CompanySingleSelectionComponent} from "@components/company/selection/single/company-single-selection.component";
import {TransferRequestSearch} from "@model/search/transfer-request-search";
import {CompanySearch} from "@model/search/company-search";
import {WarehouseSearch} from "@model/search/warehouse-search";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {debounceTime} from "rxjs";
import {TriStateCheckboxModule} from "primeng/tristatecheckbox";
import {Button} from "primeng/button";
import {Router} from "@angular/router";

@Component({
  selector: 'foodbank-transfer-request-list',
  templateUrl: './transfer-request-list.component.html',
  standalone: true,
  imports: [
    TableModule,
    CompanyComponent,
    FormsModule,
    UserComponent,
    CheckboxModule,
    WarehouseComponent,
    WarehouseSingleSelectionComponent,
    CompanySingleSelectionComponent,
    TriStateCheckboxModule,
    Button,
  ],
  styleUrls: ['./transfer-request-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferRequestListComponent {

  transferRequest = model<TransferRequest>();

  pagination: WritableSignal<Pagination>;
  transferRequestSearch: Signal<TransferRequestSearch>;
  transferRequestPage: Signal<Page<TransferRequest> | undefined>;

  companySearch: Signal<CompanySearch>;
  warehouseSearch: Signal<WarehouseSearch>;

  selectedCompany = signal<Company | undefined>(undefined);
  selectedSourceWarehouse = signal<Warehouse | undefined>(undefined);
  commentContains = signal<string | undefined>(undefined);

  showClosed = signal<boolean | undefined>(undefined);
  #transferRequestService = inject(TransferRequestService);
  #paginationService = inject(PaginationService);

  #injector = inject(Injector);
  #router = inject(Router);

  constructor() {
    this.companySearch = computed(() => ({
      transferRequestSearch: {},
    }));

    this.warehouseSearch = computed(() => ({
      sourceWarehouseFromTransferRequestSearch: {},
    }));

    const debouncedCommentContains = toSignal(toObservable(this.commentContains).pipe(debounceTime(500)));

    this.pagination = this.#paginationService.getDefaultPaginationSignal();
    this.transferRequestSearch = computed(() => ({
      company: this.selectedCompany(),
      warehouse: this.selectedSourceWarehouse(),
      commentContains: debouncedCommentContains(),
      closed: this.showClosed(),
    }));
    this.transferRequestPage = derivedAsync(() => this.#transferRequestService.findTransferRequests$(this.transferRequestSearch(), this.pagination(), this.#injector));
  }

  showTransferRequest(transferRequest: TransferRequest) {
    this.#router.navigate(['transfers/requests', transferRequest.id]);
  }

  handleLazyLoadEvent(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  typing(transferRequest: TransferRequest): TransferRequest {
    return transferRequest;
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  protected readonly console = console;
}
