import {Component, inject, input, model, Signal} from '@angular/core';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {Warehouse} from '@model/warehouse';
import {WarehouseService} from "@services/warehouse.service";
import {map, pipe, switchMap} from "rxjs";
import {Page} from "@typedefs/page";
import {pipeSignal} from "@util/foodbanks-signal-rxjs-interop";
import {enrichPageWithSelection} from "@util/foodbanks-list-utils";
import {SAME_WAREHOUSE} from "@util/foodbanks-comparators";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";

@Component({
  selector: 'foodbank-warehouse-selection',
  templateUrl: './warehouse-single-selection.component.html',
  styleUrls: ['./warehouse-single-selection.component.scss'],
  imports: [
    DropdownModule,
    FormsModule,
    WarehouseComponent
  ],
  standalone: true
})
export class WarehouseSingleSelectionComponent {

  ngModel = model<Warehouse>();
  required = input(false);
  warehouseSearch = input<WarehouseSearch>({}); // you could for example create a warehouse filter to get warehouses of a specific user
  showClear = input(true);

  warehousesPage: Signal<Page<Warehouse> | undefined>;

  readonly warehouseService = inject(WarehouseService);

  constructor() {

    this.warehousesPage = pipeSignal(this.warehouseSearch, pipe(
        switchMap(warehouseSearch => this.warehouseService.findWarehouses$(warehouseSearch, {page: 0, size: 1000})),
        map(warehousePage => enrichPageWithSelection(warehousePage, this.ngModel(), SAME_WAREHOUSE)),
      )
    );
  }

}
