<ng-container *ngIf="selectionMode === 'multiple'">
  <p-multiSelect [ngModel]="selectedArticleCategories" [options]="(articleCategories$ | async) || []" placeholder="Any"
                 filterMatchMode="contains"
                 (onChange)="select($event.value)" (onClear)="clear()" [showClear]="true" filterBy="id,nameFr,nameNl"
                 appendTo="body" dataKey="id">
    <ng-template let-selectedItems pTemplate="selectedItems">
      <ng-container *ngIf="selectedItems?.length > 0">
        <ng-container *ngFor="let selectedCategory of selectedItems.slice(0,3)">
          <foodbank-article-category [articleCategory]="selectedCategory" />
          <br/>
        </ng-container>
        <ng-container *ngIf="selectedItems.length > 3" i18n>... {{selectedItems.length - 3}} more item(s) selected
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!selectedArticleCategories || selectedArticleCategories.length === 0" i18n>Any</ng-container>
    </ng-template>

    <ng-template let-option pTemplate="item">
      <foodbank-article-category [articleCategory]="option" />
    </ng-template>
  </p-multiSelect>
</ng-container>

<ng-container *ngIf="selectionMode === 'single' && selectedArticleCategory">
  <p-dropdown [options]="(articleCategories$ | async) || []" [ngModel]="selectedArticleCategory"
              placeholder="Select a category" (onChange)="selectOne($event.value)">
    <ng-template let-value pTemplate="selectedItem">
      <foodbank-article-category [articleCategory]="value" />
    </ng-template>
    <ng-template let-articleCategory pTemplate="item">
      <foodbank-article-category [articleCategory]="articleCategory" />
    </ng-template>
  </p-dropdown>
</ng-container>
