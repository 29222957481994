<ng-container *ngIf="selectionMode === 'multiple'">
  <p-multiSelect [ngModel]="selectedArticleStorageConditions" [options]="(articleStorageConditions$ | async) || []" placeholder="Any" filterMatchMode="contains"
                 (onChange)="select($event.value)" (onClear)="clear()" [showClear]="true" filterBy="id,nameFr,nameNl" appendTo="body">
    <ng-template let-value pTemplate="selectedItems">
      <ng-container *ngFor="let selectedStorageCondition of selectedArticleStorageConditions">
        <foodbank-article-storage-condition [articleStorageCondition]="selectedStorageCondition" [showId]="false" />
        <br />
      </ng-container>
      <ng-container *ngIf="!selectedArticleStorageConditions || selectedArticleStorageConditions.length === 0" i18n>Any</ng-container>
    </ng-template>
    <ng-template let-option pTemplate="item">
      <foodbank-article-storage-condition [articleStorageCondition]="option" [showId]="false" />
    </ng-template>
  </p-multiSelect>
</ng-container>

<ng-container *ngIf="selectionMode === 'single' && selectedArticleStorageCondition">
  <p-dropdown [options]="(articleStorageConditions$ | async) || []" [ngModel]="selectedArticleStorageCondition"
              placeholder="Select a storage condition" (onChange)="selectOne($event.value)">
    <ng-template let-value pTemplate="selectedItem">
      <foodbank-article-storage-condition [articleStorageCondition]="value" />
    </ng-template>
    <ng-template let-value pTemplate="item">
      <foodbank-article-storage-condition [articleStorageCondition]="value" />
    </ng-template>
  </p-dropdown>
</ng-container>
