<ng-container *ngIf="(receptionItemPage$ | async) as receptionItems">
  <ng-container *ngFor="let receptionItem of receptionItems.content">
    <div class="w-12 mt-2" [class.reception-item-invalid-card]="receptionItem.palletQuantity < 1">
      <p-card>
        <div class="grid justify-content-between">
          <div class="grid align-content-center">
            <h3 class="col-fixed ml-3" i18n>Reception item #{{ receptionItem.rank }}</h3>
            <div *ngIf="reception && !reception.valid" class="ml-1">
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-primary mr-2" (click)="openEditReceptionItemDialog(receptionItem)"></button>
              <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" (click)="deleteReceptionItem(receptionItem)"></button>
            </div>
          </div>
        </div>
        <foodbank-reception-item [receptionItem]="receptionItem" />
      </p-card>
    </div>
  </ng-container>
  <ng-container *ngIf="receptionItems.content.length === 0">
    <p-card>
      <div class="grid justify-content-between">
        <div class="grid align-content-center">
          <h3 class="col-fixed ml-3" i18n>No reception items</h3>
        </div>
      </div>
    </p-card>
  </ng-container>
</ng-container>
