import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, debounceTime, distinctUntilChanged, map, mergeMap, Observable, shareReplay, tap} from "rxjs";
import {ArticleCategory} from "@model/article-category";
import {ArticleCategorySearch} from "@model/search/article-category-search";
import {ArticleCategoryService} from "@services/article-category.service";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgIf} from "@angular/common";
import {LanguageDirective} from "@directives/language.directive";

@Component({
  selector: 'foodbank-article-category-single-selection',
  templateUrl: './article-category-single-selection.component.html',
  styleUrls: ['./article-category-single-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    AsyncPipe,
    NgIf,
    LanguageDirective
  ]
})
export class ArticleCategorySingleSelectionComponent implements OnInit, OnChanges {

  @Input()
  selectedArticleCategory: ArticleCategory | undefined;

  @Input()
  articleCategorySearch?: ArticleCategorySearch;
  articleCategorySearch$!: BehaviorSubject<ArticleCategorySearch>;

  @Output()
  onArticleCategorySelected: EventEmitter<ArticleCategory> = new EventEmitter<ArticleCategory>();

  articleCategories$!: Observable<ArticleCategory[]>;

  loading = true;

  constructor(private articleCategoryService: ArticleCategoryService) {
  }

  ngOnInit(): void {
    this.articleCategorySearch$ = new BehaviorSubject<ArticleCategorySearch>(
      this.articleCategorySearch || {});
    this.articleCategories$ = this.articleCategorySearch$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(() => this.loading = true),
      mergeMap(articleCategorySearch =>
        this.articleCategoryService.findArticleCategories$(articleCategorySearch, {page: 0, size: 1000})),
      tap(() => this.loading = false),
      map(page => page.content),
      shareReplay()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['articleCategorySearch'] && this.articleCategorySearch) {
      this.articleCategorySearch$.next(this.articleCategorySearch)
    }
  }

  select(selectedArticleCategory?: ArticleCategory) {
    this.selectedArticleCategory = selectedArticleCategory;
    this.onArticleCategorySelected.emit(selectedArticleCategory);
  }

  clear() {
    this.select(undefined);
  }

  private searchArticleCategories() {
    if (this.articleCategorySearch && this.articleCategorySearch$) {
      this.articleCategorySearch$.next(this.articleCategorySearch);
    }
  }
}
