<p-multiSelect [(ngModel)]="ngModel"
               [options]="supplierCategoryPage()?.content || []"
               placeholder="Any"
               filterMatchMode="contains"
               [showClear]="true"
               filterBy="id,nameFr,nameNl"
               appendTo="body">
  <ng-template let-selectedCategories pTemplate="selectedItems">
    <ng-container *ngFor="let selectedCategory of selectedCategories">
      <foodbank-supplier-category ngDefaultControl [ngModel]="selectedCategory" />
      <br/>
    </ng-container>
    <ng-container *ngIf="!selectedCategories?.length" i18n>Any</ng-container>
  </ng-template>
  <ng-template let-option pTemplate="item">
    <foodbank-supplier-category ngDefaultControl [ngModel]="option" />
  </ng-template>
</p-multiSelect>
