<p-dropdown [(ngModel)]="ngModel"
            [options]="companyPage()?.content || []"
            optionLabel="name"
            placeholder="None"
            [filter]="true"
            [required]="required()"
            filterMatchMode="contains"
            filterBy="id,name"
            [showClear]="showClear()"
            dataKey="id"
            appendTo="body">
  <ng-template pTemplate="selectedItem">
    <foodbank-company ngDefaultControl [ngModel]="ngModel()"/>
  </ng-template>
  <ng-template let-company pTemplate="item">
    <foodbank-company ngDefaultControl [ngModel]="company"/>
  </ng-template>
</p-dropdown>
