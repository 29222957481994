import { ChangeDetectionStrategy, Component, computed, inject, Injector, model, signal, Signal, WritableSignal } from '@angular/core';
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { DEFAULT_ROWS_PER_PAGE, PaginationService } from '@services/pagination.service';
import { TransferRequest } from "@model/transfer-request";
import { Pagination } from "@services/pagination";
import { Page } from "@typedefs/page";
import { derivedAsync } from "ngxtension/derived-async";
import { CompanyComponent } from "@components/company/company.component";
import { FormsModule } from "@angular/forms";
import { UserComponent } from "@components/users/user.component";
import { CheckboxModule } from "primeng/checkbox";
import { WarehouseComponent } from "@components/warehouse/warehouse.component";
import { WarehouseSingleSelectionComponent } from "@components/warehouse/selection/single/warehouse-single-selection.component";
import { CompanySingleSelectionComponent } from "@components/company/selection/single/company-single-selection.component";
import { TriStateCheckboxModule } from "primeng/tristatecheckbox";
import { Button } from "primeng/button";
import { TransferRequestItemSearch } from "@model/search/transfer-request-item-search";
import { TransferRequestItemService } from "@services/transfer-request-item.service";
import { AsyncPipe, DatePipe, NgIf } from "@angular/common";
import { ArticleComponent } from "@components/article/article.component";
import { TransferRequestItemGroup } from "@model/transfer-request-item.group";
import { Warehouse } from "@model/warehouse";
import { debounceTime, map, of } from "rxjs";
import { TransferRequestTargetService } from "@services/transfer-request-target.service";
import { DragDropModule } from "primeng/dragdrop";
import { TabViewModule } from "primeng/tabview";
import { InputTextModule } from "primeng/inputtext";
import { TransferRequestItem } from "@model/transfer-request-item";
import { TransferRequestTarget } from "@model/transfer-request-target";
import { ArticleSingleSelectionComponent } from "@components/article/selection/single/article-single-selection.component";
import { ArticleMultiSelectionComponent } from "@components/article/selection/multi/article-multi-selection.component";
import { ArticleSearch } from "@model/search/article-search";
import { Article } from "@model/article";
import { Reception } from "@model/reception";
import { DatePickerComponent } from "@components/date/date-picker/date-picker.component";
import { pipeSignal } from "@util/foodbanks-signal-rxjs-interop";

type TransferRequestItemGroupRow = TransferRequestItemGroup & { total: Signal<number | undefined> };

@Component({
  selector: 'foodbank-transfer-request-item-list',
  templateUrl: './transfer-request-item-list.component.html',
  standalone: true,
  imports: [
    TableModule,
    CompanyComponent,
    FormsModule,
    UserComponent,
    CheckboxModule,
    WarehouseComponent,
    WarehouseSingleSelectionComponent,
    CompanySingleSelectionComponent,
    TriStateCheckboxModule,
    Button,
    DatePipe,
    ArticleComponent,
    AsyncPipe,
    DragDropModule,
    NgIf,
    TabViewModule,
    InputTextModule,
    ArticleSingleSelectionComponent,
    ArticleMultiSelectionComponent,
    DatePickerComponent,
  ],
  styleUrls: ['./transfer-request-item-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferRequestItemListComponent {

  transferRequest = model<TransferRequest>();

  pagination: WritableSignal<Pagination>;
  transferRequestItemSearch: Signal<TransferRequestItemSearch>;
  transferRequestItemGroupPage: Signal<Page<TransferRequestItemGroupRow> | undefined>;
  transferRequestTargetPage: Signal<Page<TransferRequestTarget> | undefined>;
  targetWarehouses: Signal<Warehouse[] | undefined>;

  articleSearch: Signal<ArticleSearch>;

  articleFilter = signal<Article | undefined>(undefined);
  receptionFilter = signal<Reception | undefined>(undefined);
  fullDescriptionFilter = signal<string | undefined>(undefined);
  exactDeliverBeforeDateFilter = signal<Date | undefined>(undefined);

  #transferRequestItemService = inject(TransferRequestItemService);
  #transferRequestTargetService = inject(TransferRequestTargetService);

  #paginationService = inject(PaginationService);
  #injector = inject(Injector);

  constructor() {
    this.articleSearch = computed(() => ({
      transferRequestSearch: {
        exactTransferRequest: this.transferRequest(),
      },
    }));

    const debouncedFullDescriptionFilter = pipeSignal(this.fullDescriptionFilter, debounceTime(300));

    this.pagination = this.#paginationService.getDefaultPaginationSignal();
    this.transferRequestItemSearch = computed(() => ({
      transferRequest: this.transferRequest(),
      article: this.articleFilter(),
      reception: this.receptionFilter(),
      exactDeliverBeforeDate: this.exactDeliverBeforeDateFilter(),
      fullDescriptionContains: debouncedFullDescriptionFilter(),
    }));

    this.transferRequestItemGroupPage = derivedAsync(() => this.#transferRequestItemService
      .findTransferRequestItemGroups$(this.transferRequestItemSearch(), this.pagination(), this.#injector)
      .pipe(map(page => {
        return {
          ...page,
          content: page?.content.map(transferRequestItemGroup => this.mapToTransferRequestItemGroupRow(transferRequestItemGroup)),
        } as Page<TransferRequestItemGroupRow>;
      }))
    );

    // find warehouses
    this.transferRequestTargetPage = derivedAsync(() => !this.transferRequest() ? of() : this.#transferRequestTargetService.findTransferRequestTargets$({transferRequest: this.transferRequest()}, {page: 0, size: 0}, this.#injector));
    this.targetWarehouses = computed(() => this.transferRequestTargetPage()?.content
      .map(transferRequestTarget => transferRequestTarget.targetWarehouse()).filter(warehouse => !!warehouse));
  }

  handleLazyLoadEvent(event: TableLazyLoadEvent) {
    const pagination = this.#paginationService.getTablePagination(event);
    this.pagination.set(pagination);
  }

  typing(transferRequestItemGroup: TransferRequestItemGroupRow): TransferRequestItemGroupRow {
    return transferRequestItemGroup;
  }

  updatePalletQuantity(transferRequestItemGroup: TransferRequestItemGroup, targetWarehouse: Warehouse, newPalletQuantity: number) {
    console.debug(`Updating pallet quantity for article ${transferRequestItemGroup.article()?.id} to ${newPalletQuantity} for warehouse ${targetWarehouse.name}`);
    const warehouseTransferRequestItemMap = transferRequestItemGroup.warehouseTransferRequestItemMap();
    const warehouseTransferRequestItem: TransferRequestItem = warehouseTransferRequestItemMap.get(targetWarehouse.id) ?? {
      article: transferRequestItemGroup.article,
      reception: transferRequestItemGroup.reception,
      transferRequestTarget: this.#getTransferRequestTarget(targetWarehouse),
      deliverBeforeDate: transferRequestItemGroup.deliverBeforeDate,
      fullDescription: transferRequestItemGroup.fullDescription,
    };
    warehouseTransferRequestItem.palletQuantity = newPalletQuantity;
    warehouseTransferRequestItemMap.set(targetWarehouse.id, warehouseTransferRequestItem);
    // TODO: actually call service
  }

  mapToTransferRequestItemGroupRow(transferRequestItemGroup: TransferRequestItemGroup): TransferRequestItemGroupRow {
    return {
      ...transferRequestItemGroup,
      total:
        computed(() => {
          const transferRequestItems = transferRequestItemGroup.warehouseTransferRequestItemMap().values();

          const transferRequestItemArray = Array.from(transferRequestItems);
          return transferRequestItemArray.map(transferRequestItem => transferRequestItem.palletQuantity)
            .reduce((quantity1, quantity2) => this.#add(quantity1, quantity2), undefined)
        })
    }
  }

  #add(quantity1?: number, quantity2?: number): number | undefined {
    if (!quantity1 && !quantity2) {
      return undefined;
    }
    return (quantity1 ?? 0) + (quantity2 ?? 0);
  }

  #getTransferRequestTarget(targetWarehouse: Warehouse): Signal<TransferRequestTarget | undefined> {
    return computed(() => {
      const transferRequestTargets = this.transferRequestTargetPage()?.content;
      const matchingTransferRequestTargets = transferRequestTargets?.filter(transferRequestTarget => transferRequestTarget.targetWarehouse()?.id === targetWarehouse.id);
      return matchingTransferRequestTargets ? matchingTransferRequestTargets[0] : undefined;
    });
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;
  protected readonly Date = Date;

  handleExactDeliverBeforeDateSelection(value: Date | Date[] | undefined) {
    if (value instanceof Date) {
      this.exactDeliverBeforeDateFilter.set(value);
    }
  }
}
