<h1>Fead planning by organization</h1>
<div class="field grid">
  <label for="warehouse" class="col-1" i18n>Warehouse</label>
  <div class="col-3">
    <ng-container *ngIf="(warehouseSearch$ | async) as warehouseSearch">
      <foodbank-warehouse-selection id="warehouse"
        [warehouseSearch]="warehouseSearch"
        [ngModel]="warehouse"
        (ngModelChange)="selectWarehouse($event)"
        ngDefaultControl
       />
    </ng-container>
  </div>
</div>

<div *ngIf="warehouse" class="field grid">
  <label for="campaign" class="col-1" i18n>Campaign</label>
  <div class="col-3">
    <foodbank-fead-campaign-selection id="campaign"
      [warehouse]="warehouse"
      (onCampaignSelected)="selectCampaign($event)" />
  </div>
</div>

<div *ngIf="campaign" class="field grid">
  <label for="organization" class="col-1" i18n>Organization</label>
  <div class="col-3">
    <foodbank-organization-selection ngDefaultControl
      id="organization"
      [organizationSearch]="organizationSearch"
      (ngModelChange)="selectOrganization($event)"
     />
  </div>
</div>

<div class="field grid">
  <div class="col ui-toolbar">
    <p-button label="Save"
      icon="pi pi-check"
      styleClass="p-button-primary"
      (onClick)="save()"
      [disabled]="!updatedData"
     />
    <p-confirmDialog [style]="{width: '50vw'}" />
    <p-button label="Cancel"
      icon="pi pi-undo"
      styleClass="p-button-primary"
      (click)="this.cancel();"
      [disabled]="!(planningData$ | async)"
     />
    <p-button label="Delete"
      icon="pi pi-trash"
      styleClass="p-button-primary"
      (click)="delete()"
      [disabled]="!(planningData$ | async)"
     />
  </div>
</div>

<foodbank-fead-planning-organization-table *ngIf="organization && (planningData$ | async) as planningData"
  [planningData]="planningData"
  [organization]="organization"
  [year]="campaign?.year || 0"
  (dataChange)="planningDataUpdates($event)"
 />
