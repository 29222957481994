import {Component, inject, Input, OnInit} from '@angular/core';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {TableLazyLoadEvent} from 'primeng/table';
import {Page} from '@typedefs/page';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  shareReplay,
  switchMap
} from 'rxjs';
import {Reception} from '@model/reception';
import {ReceptionSearch} from '@model/search/reception-search';
import {Pagination} from '@services/pagination';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {SupplierSearch} from '@model/search/supplier-search';
import {ReceptionService} from '@services/reception.service';
import {UserService} from '@services/user.service';
import {Warehouse} from '@model/warehouse';
import {Supplier} from '@model/supplier';
import {ConfirmationService, FilterMetadata, MessageService} from 'primeng/api';
import {FoodbankDatePickerEvent} from '@components/date/date-picker/date-picker.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ReceptionEditComponent} from '@components/reception/reception-edit/reception-edit.component';
import {ReceptionItemEditComponent} from '@components/reception/reception-item-edit/reception-item-edit.component';
import {ReceptionItem} from "@model/reception-item";
import {ReceptionLabelsComponent} from '@components/reception/reception-labels/reception-labels.component';
import {ReceptionItemService} from '@services/reception-item.service';

export type ReceptionPagination = Pagination
type TableFilters = { [p: string]: FilterMetadata | FilterMetadata[] };
type UndefinedTableFilters = { [p: string]: FilterMetadata | FilterMetadata[] | undefined } | undefined;
type ValidReceptionsFilter = boolean | undefined;
type ValidReceptionsFilterOption = { value: ValidReceptionsFilter, name: string };

const NON_VALID_RECEPTIONS_FILTER_OPTION: ValidReceptionsFilterOption = {value: false, name: 'Not valid'};
const VALID_RECEPTIONS_FILTER_OPTION: ValidReceptionsFilterOption = {value: true, name: 'Valid'};
const ALL_RECEPTIONS_FILTER_OPTION: ValidReceptionsFilterOption = {value: undefined, name: 'All'};
const FOODBANK_PREFERENCES_VALID_RECEPTIONS_FILTER = 'FOODBANK_PREFERENCES_RECEPTIONS_FILTER_OPTION';

interface ReceptionListItem extends Reception {
  validationStatusChangeInProgress: boolean;
  canBeInvalidated: boolean;
  tooltipMessage: string;
}

@Component({
  selector: 'foodbank-reception-list',
  templateUrl: './reception-list.component.html',
  styleUrls: ['./reception-list.component.scss'],
  providers: [DialogService]
})
export class ReceptionListComponent implements OnInit {

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  @Input()
  receptionSearch = {};

  receptionSearch$!: BehaviorSubject<ReceptionSearch>;

  // main search
  pagination$!: BehaviorSubject<ReceptionPagination>;
  receptionPage$!: Observable<Page<ReceptionListItem>>;

  // searches for table filters
  warehouseSearch$!: Observable<WarehouseSearch>;
  supplierSearch$!: Observable<SupplierSearch>;
  filters: TableFilters = {valid: {value: NON_VALID_RECEPTIONS_FILTER_OPTION}};

  ref: DynamicDialogRef | undefined;
  validOptions: ValidReceptionsFilterOption[] = [
    NON_VALID_RECEPTIONS_FILTER_OPTION,
    VALID_RECEPTIONS_FILTER_OPTION,
    ALL_RECEPTIONS_FILTER_OPTION
  ];

  private refreshTrigger$ = new BehaviorSubject<void>(undefined);

  private confirmationService = inject(ConfirmationService);
  private receptionService = inject(ReceptionService);
  private receptionItemService = inject(ReceptionItemService);
  private userService = inject(UserService);
  private paginationService = inject(PaginationService);
  private messageService = inject(MessageService);
  private dialogService = inject(DialogService);

  ngOnInit() {
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    const receptionsFilterOptionPreference = localStorage.getItem(FOODBANK_PREFERENCES_VALID_RECEPTIONS_FILTER);
    const initialReceptionFilterOption = receptionsFilterOptionPreference
      ? JSON.parse(receptionsFilterOptionPreference) as ValidReceptionsFilterOption
      : NON_VALID_RECEPTIONS_FILTER_OPTION;

    this.filters = {
      valid: {value: initialReceptionFilterOption},
    };

    this.receptionSearch$ = new BehaviorSubject<ReceptionSearch>(this.receptionSearch);

    defaultWarehouse$.subscribe(defaultWarehouse => {
      this.filters['warehouse'] = {value: defaultWarehouse};
      this.receptionSearch$.next({...this.receptionSearch$.value, warehouse: defaultWarehouse});
    })

    this.pagination$ = new BehaviorSubject<ReceptionPagination>(this.paginationService.getDefaultPagination());
    const company$ = this.userService.getCurrentUserCompany$().pipe(shareReplay());

    this.warehouseSearch$ = company$.pipe(map(company => ({company: company})));
    this.supplierSearch$ = company$.pipe(map(company => ({company: company, includeCountrySuppliers: true})));

    const combinedReceptionSearch$: Observable<ReceptionSearch> = combineLatest([company$, this.receptionSearch$, this.refreshTrigger$]).pipe(
      map(([company, receptionSearch, _]) => ({
        ...receptionSearch,
        company,
      }))
    );

    this.receptionPage$ = combineLatest([combinedReceptionSearch$, this.pagination$]).pipe(
      debounceTime(10),
      switchMap(([receptionSearch, pagination]) => this.receptionService.find(receptionSearch, pagination)),
      map(page => ({
        ...page,
        content: page.content.map(reception => ({
          ...reception,
          validationStatusChangeInProgress: false,
          canBeInvalidated: reception.valid,
          tooltipMessage: reception.valid ? 'Invalidate' : 'Validate'
        }))
      })),
      shareReplay()
    );
  }

  lazyLoad(event: TableLazyLoadEvent) {
    const receptionIdStartsWith = this.getReceptionIdStartsWith(event);
    const warehouse = this.getSelectedWarehouse(event);
    const supplier = this.getSelectedSupplier(event);
    const rangeDates = this.getSelectedRangeDatesSupplier(event);
    const validReceptionsFilter = this.getValidReceptionsFilter(event);
    const pagination = this.paginationService.getTablePagination(event);

    const receptionSearch = {
      receptionIdStartsWith,
      warehouse: warehouse,
      supplier: supplier,
      valid: validReceptionsFilter.value,
      minDate: rangeDates?.[0],
      maxDate: rangeDates?.[1]
    }
    this.receptionSearch$.next(receptionSearch);

    this.pagination$.next(pagination);
  }

  private getReceptionIdStartsWith(event: TableLazyLoadEvent, filters: UndefinedTableFilters = event.filters): string | undefined {
    const id = (<FilterMetadata>filters?.['id']);
    return id?.value;
  }

  getSelectedWarehouse(event: TableLazyLoadEvent, filters: UndefinedTableFilters = event.filters): Warehouse | undefined {
    const warehouse = (<FilterMetadata>filters?.['warehouse']);
    return warehouse?.value;
  }

  getSelectedSupplier(event: TableLazyLoadEvent, filters: UndefinedTableFilters = event.filters): Supplier | undefined {
    const supplier = (<FilterMetadata>filters?.['supplier']);
    return supplier?.value;
  }

  getSelectedRangeDatesSupplier(event: TableLazyLoadEvent, filters: UndefinedTableFilters = event.filters): Date[] | undefined {
    const datePickerFilter = (<FilterMetadata>filters?.['date']);
    if (!datePickerFilter) {
      return undefined;
    }

    const datePickerEvent = <FoodbankDatePickerEvent>datePickerFilter.value;
    if (!datePickerEvent) {
      return undefined;
    }

    const {utcValue, selectionMode} = datePickerEvent;

    if (!utcValue) {
      return undefined;
    }

    if (selectionMode === 'single' && utcValue instanceof Date) {
      return [utcValue, utcValue];
    }

    return utcValue as Date[]
  }

  getValidReceptionsFilter(event: TableLazyLoadEvent, filters: UndefinedTableFilters = event.filters): ValidReceptionsFilterOption {
    const validReceptionsFilter = (<FilterMetadata>filters?.['valid']);
    const validReceptionsFilterValue = validReceptionsFilter?.value;

    if (validReceptionsFilterValue) {
      localStorage.setItem(FOODBANK_PREFERENCES_VALID_RECEPTIONS_FILTER, JSON.stringify(validReceptionsFilterValue));
    } else {
      localStorage.setItem(FOODBANK_PREFERENCES_VALID_RECEPTIONS_FILTER, JSON.stringify(NON_VALID_RECEPTIONS_FILTER_OPTION));
    }

    return validReceptionsFilterValue;
  }

  openEditDialog(reception: Reception) {
    this.ref = this.dialogService.open(ReceptionEditComponent, {
      header: 'New reception',
      width: '40%',
      data: {...reception}
    });

    this.ref.onClose.subscribe(_ => this.refresh());
  }

  openNewReceptionDialog() {
    this.ref = this.dialogService.open(ReceptionEditComponent, {
      header: 'New reception',
      width: '40%'
    });

    this.ref.onClose.subscribe(_ => this.refresh());
  }

  openNewReceptionItemDialog(reception: Reception) {
    const receptionItem: Partial<ReceptionItem> = {
      palletQuantity: 1,
      reception$: of(reception),
      article$: of(undefined),
      returnable$: of(undefined),
    };

    this.receptionItemService.saveReceptionItem$(receptionItem as ReceptionItem)
      .subscribe(receptionItem => this.openEditReceptionItemDialog(reception, receptionItem, true));
  }

  openEditReceptionItemDialog(reception: Reception, receptionItem: ReceptionItem, isNew = false) {
    if (reception?.valid) {
      return;
    }

    this.ref = this.dialogService.open(ReceptionItemEditComponent, {
      header: 'Edit Reception Item ' + receptionItem.rank,
      width: '70%',
      data: {
        isNew: isNew,
        receptionItem: receptionItem
      }
    });

    this.ref.onClose.subscribe(_ => this.refresh());
  }

  deleteReception(reception: Reception) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete reception ${reception.id} ?`,
      header: `Delete reception ${reception.id} ?`,
      icon: 'pi pi-question-circle',
      acceptLabel: 'Delete',
      rejectLabel: 'Cancel',
      accept: () => {
        this.receptionService.delete(reception)
          .subscribe(_ => {
            this.refresh();
            this.messageService.add({
              severity: 'info',
              summary: 'Deleted',
              detail: `Reception ${reception.id} data has been deleted.`
            });
          });
      }
    });
  }

  validate(reception: ReceptionListItem, rowToggleButton: HTMLButtonElement) {
    reception.validationStatusChangeInProgress = true;
    reception.tooltipMessage = 'Validating...';

    this.receptionService.saveReception({...reception, valid: true})
      .subscribe({
        next: _ => {
          this.refresh();
          reception.validationStatusChangeInProgress = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Validated',
            detail: `Reception ${reception.id} has been validated.`
          });
          reception.validationStatusChangeInProgress = false;
          reception.tooltipMessage = 'Invalidate';
          rowToggleButton.click();
        },
        error: error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.message
          });
          reception.validationStatusChangeInProgress = false;
          reception.tooltipMessage = 'Validate';
        },
      });
  }

  invalidateAfterConfirmation(reception: ReceptionListItem) {
    this.confirmationService.confirm({
      message: `<span class="text-red-600 font-bold text-lg">All stock will be removed from the warehouse and the pallet labels will be invalidated.</span>
      </br>
      </br>
      Upon re-validation, updated reception stock will be added to the warehouse and <span class="font-bold">new pallet labels</span> will be generated.
      </br>
      </br>
      </br>
      <span class="font-bold text-xl">Are you sure that you want to invalidate reception ${reception.id} ?</span>`,
      header: `Invalidate reception ${reception.id} ?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Invalidate',
      rejectLabel: 'Cancel',
      accept: () => this.invalidate(reception),
    });
  }

  invalidate(reception: ReceptionListItem) {
    reception.validationStatusChangeInProgress = true;
    reception.tooltipMessage = 'Invalidating...';
    this.receptionService.saveReception({...reception, valid: false})
      .subscribe({
        next: _ => {
          this.refresh();
          reception.validationStatusChangeInProgress = false;
        },
        error: error => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: error.error.message
          });
          reception.canBeInvalidated = false;
          reception.validationStatusChangeInProgress = false;
          reception.tooltipMessage = 'Invalidation not possible';
        },
        complete: () => reception.validationStatusChangeInProgress = false
      });
  }

  openPrintLabelsDialog(reception: Reception) {
    this.ref = this.dialogService.open(ReceptionLabelsComponent, {
      showHeader: false,
      width: '49rem',
      data: reception
    });
  }

  openFirstReceptionItemDialog(reception: Reception) {
    const receptionItem: Partial<ReceptionItem> = {
      rank: 1,
      palletQuantity: 1,
      unitWeight: 0,
      reception$: of(reception),
      article$: of(undefined),
      returnable$: of(undefined),
    };
    this.ref = this.dialogService.open(ReceptionItemEditComponent, {
      header: 'New Reception Item',
      width: '70%',
      data: {
        receptionItem: receptionItem
      }
    });

    this.ref.onClose.subscribe(_ => this.refresh());
  }

  private refresh() {
    this.refreshTrigger$.next();
  }

  fixTyping(reception: any): Reception {
    return reception;
  }
  fixReceptionListItemTyping(receptionListItem: any): ReceptionListItem {
    return receptionListItem;
  }
}
