import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, debounceTime, distinctUntilChanged, map, mergeMap, Observable, shareReplay} from 'rxjs';
import {ArticleSearch} from '@model/search/article-search';
import {ArticleService} from '@services/article.service';
import {Article} from '@model/article';
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgIf} from "@angular/common";
import {ArticleComponent} from "@components/article/article.component";
import {LanguageDirective} from "@directives/language.directive";

@Component({
  selector: 'foodbank-article-selection',
  templateUrl: './article-single-selection.component.html',
  styleUrls: ['./article-single-selection.component.scss'],
  standalone: true,
  imports: [
    DropdownModule,
    FormsModule,
    AsyncPipe,
    ArticleComponent,
    LanguageDirective,
    NgIf
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleSingleSelectionComponent implements OnInit, OnChanges {

  @Input()
  ngModel?: Article;
  @Input()
  articleSearch?: ArticleSearch; // you could for example create a article filter to get articles of a specific user
  @Output()
  ngModelChange: EventEmitter<Article> = new EventEmitter<Article>();

  articleSearch$!: BehaviorSubject<ArticleSearch>;

  articles$?: Observable<Article[]>;

  loading = true;
  @Input()
  disabled = false;

  constructor(private articleService: ArticleService) {
  }

  ngOnInit(): void {
    this.articleSearch$ = new BehaviorSubject<ArticleSearch>(this.articleSearch || {});
    this.articles$ = this.articleSearch$.pipe(
      debounceTime(100), // updates are too quick for the dropdown
      distinctUntilChanged(),
      mergeMap(articleSearch => {
        this.loading = true;
        return this.articleService.findArticles$(articleSearch, {page: 0, size: 1000});
      }),
      map(page => {
        this.loading = false;
        return page.content;
      }),
      shareReplay()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['articleSearch']) {
      this.searchArticles();
    }
    if (changes['selectedArticle'] &&
      changes['selectedArticle'].currentValue !== changes['selectedArticle'].previousValue &&
      changes['selectedArticle'].currentValue !== this.ngModel) {
      console.debug('selectedArticle changed');

      this.select(changes['selectedArticle'].currentValue);
    }
  }

  select(selectedArticle: Article | undefined) {
    this.ngModel = selectedArticle;
    this.ngModelChange.emit(selectedArticle);
  }

  clear() {
    this.select(undefined);
  }

  private searchArticles() {
    if (this.articleSearch && this.articleSearch$) {
      this.articleSearch$.next(this.articleSearch);
    }
  }
}
