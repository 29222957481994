import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {map, mergeMap, Observable, shareReplay} from 'rxjs';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {WarehouseService} from '@services/warehouse.service';
import {Warehouse} from '@model/warehouse';
import {ScrollerOptions} from "primeng/api";
import {PaginationService} from "@services/pagination.service";
import {MultiSelectModule} from "primeng/multiselect";
import {FormsModule} from "@angular/forms";
import {AsyncPipe, NgForOf, NgIf} from "@angular/common";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";

@Component({
  selector: 'foodbank-warehouses-selection',
  templateUrl: './warehouse-multiple-selection.component.html',
  styleUrls: ['./warehouse-multiple-selection.component.scss'],
  standalone: true,
  imports: [
    MultiSelectModule,
    FormsModule,
    AsyncPipe,
    WarehouseComponent,
    NgIf,
    NgForOf
  ]
})
export class WarehouseMultipleSelectionComponent implements OnChanges {

  @Input()
  selectedWarehouses: Warehouse[] = [];
  @Input()
  warehouseSearch: WarehouseSearch = {}; // you could for example create a warehouse filter to get warehouses of a specific user
  @Output()
  onWarehousesSelected: EventEmitter<Warehouse[]> = new EventEmitter<Warehouse[]>();

  warehouses$!: Observable<Warehouse[]>;
  virtualScrollOptions: ScrollerOptions = {
    lazy: true,
    onLazyLoad: this.onLazyLoad.bind(this)
  };

  expectedWarehouseItemSizeInPixels = 46;

  constructor(
    private warehouseService: WarehouseService,
    private paginationService: PaginationService,
  ) {
  }

  ngOnChanges(): void {
    this.warehouses$ = this.warehouseService.findWarehouses$(this.warehouseSearch, this.paginationService.getDefaultPagination())
      .pipe(
        map(page => page.content),
        shareReplay(),
      );
  }

  select(warehouses: Warehouse[]) {
    this.selectedWarehouses = warehouses;
    this.onWarehousesSelected.emit(warehouses);
  }

  clear() {
    this.selectedWarehouses = [];
    this.onWarehousesSelected.emit([]);
  }

  onLazyLoad(event: any) {
    const first: number = event.first;
    const rows: number = event.last;

    const pagination = this.paginationService.getPagination(first, rows);
    this.warehouses$.pipe(
      mergeMap(loadedWarehouses => this.warehouseService.findWarehouses$(this.warehouseSearch, pagination)
        .pipe(
          map(newWarehousePage => loadedWarehouses.concat(newWarehousePage.content)),
          shareReplay(),
        )
      )
    )
  }
}
