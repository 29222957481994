import {Injectable, Injector} from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {map, Observable, shareReplay, take} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {Article} from '@model/article';
import {ArticleSearch} from '@model/search/article-search';
import {ArticleDto, ArticleSearchDto} from '@typedefs/stock-rest';
import {ArticleCategoryService} from './article-category.service';
import {ArticleStorageConditionService} from './article-storage-condition.service';
import {StockService} from '@services/stock.service';
import {StockPrevisionService} from '@services/stock-prevision.service';
import {TransferRequestService} from "@services/transfer-request.service";

type ArticlePagination = Pagination

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private httpClient: HttpClient,
              private articleCategoryService: ArticleCategoryService,
              private articleStorageConditionService: ArticleStorageConditionService,
              private injector: Injector) {
  }

  public getArticle$(id: string): Observable<Article> {
    return this.httpClient.get<ArticleDto>(`${environment.apiUrl}/articles/${id}`)
      .pipe(
        map(articleDto => this.mapToArticle$(articleDto)),
        take(1), // this fixes some circular dependency issues when serializing, really strange!!!
        shareReplay(1)
      );
  }

  public findArticles$(articleSearch: ArticleSearch, pagination?: ArticlePagination): Observable<Page<Article>> {
    const articleSearchDto = this.mapToArticleSearchDto(articleSearch);
    return this.httpClient.post<Page<ArticleDto>>(`${environment.apiUrl}/articles/search`, articleSearchDto, {params: pagination})
      .pipe(map(articlePage => this.loadArticlePage(articlePage)));
  }

  private loadArticlePage(articleDtoPage: Page<ArticleDto>): Page<Article> {
    return {
      ...articleDtoPage,
      content: this.loadArticleDetailsList(articleDtoPage.content)
    };
  }

  public loadArticleDetailsList(articleDtos: ArticleDto[]) {
    return articleDtos.map(article => this.mapToArticle$(article));
  }

  // TODO: pass article!!!
  updateArticle(article: ArticleDto): Observable<ArticleDto> {
    return this.httpClient.put<ArticleDto>(`${environment.apiUrl}/articles/${article.id}`, article)
  }

  public mapToArticle$(articleDto: ArticleDto): Article {
    return {
      ...articleDto,
      articleCategory$: this.articleCategoryService.findArticleCategory$(articleDto.articleCategoryId).pipe(shareReplay(1)),
      articleStorageCondition$: this.articleStorageConditionService.findArticleStorageCondition$(articleDto.articleStorageConditionId).pipe(shareReplay(1))
    };
  }

  mapToArticleSearchDto(articleSearch: ArticleSearch): ArticleSearchDto {
    const stockService = this.injector.get(StockService);
    const stockPrevisionService = this.injector.get(StockPrevisionService);
    const transferRequestService = this.injector.get(TransferRequestService);
    return {
      nameContains: articleSearch.nameContains,
      fead: articleSearch.fead,
      feadYear: articleSearch.feadYear,
      articleIds: articleSearch.articles?.map(article => article.id),
      articleCategorySearchDto: articleSearch.articleCategorySearch ? this.articleCategoryService.mapToArticleCategorySearchDto(articleSearch.articleCategorySearch) : undefined,
      articleStorageConditionSearchDto: articleSearch.articleStorageConditionSearch && this.articleStorageConditionService.mapToArticleStorageConditionSearchDto(articleSearch.articleStorageConditionSearch),
      preparationId: articleSearch.preparation?.id,
      stockSearchDto: articleSearch.stockSearch && stockService.mapToStockSearchDto(articleSearch.stockSearch),
      stockPrevisionSearchDto: articleSearch.stockPrevisionSearch ? stockPrevisionService.mapToStockPrevisionSearchDto(articleSearch.stockPrevisionSearch) : undefined,
      transferRequestSearchDto: articleSearch.transferRequestSearch ? transferRequestService.mapToTransferRequestSearchDto(articleSearch.transferRequestSearch) : undefined,
      ...(articleSearch.bulkFood !== undefined ? { bulkFood: articleSearch.bulkFood } : {})
    }
  }

}
