<div class="flex align-items-center">
  <div class="flex m-2">
    <span class="font-semibold" i18n>Selection mode:</span>
  </div>
  <div class="flex m-2 align-items-center">
    <p-radioButton name="dateSelectionMode" id="date-selection-mode-single" value="single" [(ngModel)]="dateSelectionMode" (onClick)="onDateSelectionModeChanged($event)" />
    <label class="ml-1" for="date-selection-mode-single" i18n>Single</label>
  </div>
  <div class="flex m-2 align-items-center">
    <p-radioButton name="dateSelectionMode" id="date-selection-mode-range" value="range" [(ngModel)]="dateSelectionMode" (onClick)="onDateSelectionModeChanged($event)" />
    <label class="ml-1" for="date-selection-mode-range" i18n>Range</label>
  </div>
</div>
