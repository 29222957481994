@let company = ngModel() ;
@if (company) {
  <span>
    <p class="label">
      <i class="fa-solid fa-building mr-1"></i>
      @if (showId()) {
        <span class="id mr-1">#{{ company.id }}</span>
      }
      @if (showName()) {
        <span class="name">{{ company.name }}</span>
      }
    </p>
  </span>
}
