import {ChangeDetectionStrategy, Component, computed, inject, Injector, signal, Signal} from '@angular/core';
import {DEFAULT_ROWS_PER_PAGE} from '@services/pagination.service';
import {TransferRequestService} from "@services/transfer-request.service";
import {TransferRequest} from "@model/transfer-request";
import {injectParams} from "ngxtension/inject-params";
import {derivedAsync} from "ngxtension/derived-async";
import {of} from "rxjs";
import {TransferRequestTargetListComponent} from "@components/transfer/request/request-target/transfer-request-target-list.component";
import {PanelModule} from "primeng/panel";
import {TransferRequestItemListComponent} from "@components/transfer/request/item/transfer-request-item-list.component";
import {TransferRequestTargetService} from "@services/transfer-request-target.service";
import {DragDropModule} from "primeng/dragdrop";
import {NgIf} from "@angular/common";
import {TabViewModule} from "primeng/tabview";
import {StockListComponent} from "@components/stock/stock-list.component";
import {Stock} from "@model/stock";

@Component({
  selector: 'foodbank-transfer-request-edit',
  templateUrl: './transfer-request-edit.component.html',
  standalone: true,
  imports: [
    TransferRequestTargetListComponent,
    PanelModule,
    TransferRequestItemListComponent,
    DragDropModule,
    NgIf,
    TabViewModule,
    StockListComponent
  ],
  styleUrls: ['./transfer-request-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferRequestEditComponent {

  transferRequestIdParam = injectParams('id');
  transferRequestParam = this.#getTransferRequestParam();
  transferRequest: Signal<TransferRequest | undefined>;

  targetWarehouseCount: Signal<number | undefined>;

  activeTabIndex = signal(2);

  #draggedStocks = signal<Stock[]>([]);
  #droppedStocksForTransferRequestCreation = signal<Stock[]>([]);

  #transferRequestService = inject(TransferRequestService);
  #transferRequestTargetService = inject(TransferRequestTargetService);
  #injector = inject(Injector);

  constructor() {
    // count target warehouses to display in caption of warehouse collapsable panel
    const transferRequestTargetPageForCount = derivedAsync(() => !this.transferRequestParam() ? of() : this.#transferRequestTargetService.findTransferRequestTargets$({transferRequest: this.transferRequestParam()}, {
      page: 0,
      size: 0
    }, this.#injector));
    this.targetWarehouseCount = computed(() => transferRequestTargetPageForCount()?.numberOfElements);

    this.transferRequest = derivedAsync(() => {
      const transferRequestParam = this.transferRequestParam();
      const droppedStocksForTransferRequestCreation = this.#droppedStocksForTransferRequestCreation();
      if (!transferRequestParam || !droppedStocksForTransferRequestCreation.length) {
        console.log('loading from param')
        return transferRequestParam;
      }
      console.log('loading from svc')
      return this.#transferRequestService.createTransferRequestItemsFromStocks(transferRequestParam, droppedStocksForTransferRequestCreation, this.#injector);
    });
  }

  /**
   * Get transfer request from input param.
   */
  #getTransferRequestParam(): Signal<TransferRequest | undefined> {
    // TODO: I'm sure this could be much better
    return derivedAsync(() => {
      const transferRequestIdParam = this.transferRequestIdParam();
      if (!transferRequestIdParam) {
        return of(undefined);
      }
      const transferRequestId = Number(transferRequestIdParam);
      return this.#transferRequestService.getTransferRequest$(transferRequestId, this.#injector);
    });
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  removeSelectedTransferRequestItems() {

  }

  createTransferRequestItemsFromDraggedStock() {
    const draggedStocks = this.#draggedStocks();
    this.#droppedStocksForTransferRequestCreation.set(draggedStocks);
    this.#draggedStocks.set([]);
  }

  startStockDrag(stocks: Stock[]) {
    this.#draggedStocks.set(stocks);
  }
}
