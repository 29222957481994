<div (click)="$event.stopPropagation()">
    <h5>Scale</h5>
    <div class="flex align-items-center">
        <button icon="pi pi-minus" type="button" pButton (click)="decrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem mr-2" [disabled]="scale === scales[0]"></button>
        <div class="flex gap-2 align-items-center">
            <i class="pi pi-circle-fill text-300" *ngFor="let s of scales" [ngClass]="{'text-primary-500': s === scale}"></i>
        </div>
        <button icon="pi pi-plus"  type="button" pButton (click)="incrementScale()" class="p-button-text p-button-rounded w-2rem h-2rem ml-2" [disabled]="scale === scales[scales.length - 1]"></button>
    </div>

    <ng-container *ngIf="!minimal">
        <h5>Menu Type</h5>
        <div class="field-radiobutton">
            <p-radioButton name="menuMode" value="static" [(ngModel)]="menuMode" inputId="mode1" ngDefaultControl />
            <label for="mode1">Static</label>
        </div>
        <div class="field-radiobutton">
            <p-radioButton name="menuMode" value="overlay" [(ngModel)]="menuMode" inputId="mode2" ngDefaultControl />
            <label for="mode2">Overlay</label>
        </div>
    </ng-container>
</div>
