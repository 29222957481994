import {inject, Injectable, Injector, Signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {WarehouseService} from './warehouse.service';
import {TransferRequestTargetDto, TransferRequestTargetSearchDto} from '@typedefs/stock-rest';
import {derivedAsync} from "ngxtension/derived-async";
import {copyCommonFields} from "@model/mapping-utils";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {TransferRequestService} from "@services/transfer-request.service";
import {TransferRequestTargetSearch} from "@model/search/transfer-request-target-search";
import {Cache} from "@util/cache";
import {TransferRequest} from "@model/transfer-request";
import {Warehouse} from "@model/warehouse";

interface TransferRequestTargetServiceCache {
  warehouseCache: Cache<number, Signal<Warehouse | undefined>>,
  transferRequestCache: Cache<number, Signal<TransferRequest | undefined>>,
}

@Injectable({
  providedIn: 'root'
})
export class TransferRequestTargetService {

  #httpClient = inject(HttpClient);
  #warehouseService = inject(WarehouseService);
  #transferRequestService = inject(TransferRequestService);

  public getTransferRequestTarget$(transferRequestTargetId: number, injector: Injector): Observable<TransferRequestTarget> {
    const cache = this.#createCache();

    return this.#httpClient.get<TransferRequestTargetDto>(`${environment.apiUrl}/transfer/request/target/${transferRequestTargetId}`)
      .pipe(
        map(transferRequestTargetDto => this.mapToTransferRequestTarget(transferRequestTargetDto, injector, cache)),
      );
  }

  public findTransferRequestTargets$(transferRequestTargetSearch: TransferRequestTargetSearch, pagination: Pagination, injector: Injector): Observable<Page<TransferRequestTarget> | undefined> {
    const transferRequestTargetSearchDto = this.mapToTransferRequestTargetSearchDto(transferRequestTargetSearch);

    const cache = this.#createCache();

    return this.#httpClient.post<Page<TransferRequestTargetDto>>(`${environment.apiUrl}/transfer/request/target/search`, transferRequestTargetSearchDto, {params: pagination})
      .pipe(map(transferRequestTargetDtoPage => {
        const transferRequestTargets: TransferRequestTarget[] = transferRequestTargetDtoPage.content.map(transferRequestTargetDto => this.mapToTransferRequestTarget(transferRequestTargetDto, injector, cache));
        return {
          ...transferRequestTargetDtoPage,
          content: transferRequestTargets
        }
      }));
  }

  public mapToTransferRequestTarget(transferRequestTargetDto: TransferRequestTargetDto, injector: Injector, cache: TransferRequestTargetServiceCache): TransferRequestTarget {
    const transferRequestId = transferRequestTargetDto.transferRequestId;
    const targetWarehouseId = transferRequestTargetDto.targetWarehouseId;
    return {
      ...transferRequestTargetDto,
      transferRequest: cache.transferRequestCache.computeIfAbsent(transferRequestId, () => this.#loadTransferRequest(transferRequestId, injector)),
      targetWarehouse: cache.warehouseCache.computeIfAbsent(targetWarehouseId, () => this.#loadTargetWarehouse(transferRequestTargetDto.targetWarehouseId, injector)),
    };
  }

  public mapToTransferRequestTargetSearchDto(transferRequestTargetSearch: TransferRequestTargetSearch): TransferRequestTargetSearchDto {
    const commonFields: TransferRequestTargetSearchDto | TransferRequestTargetSearch = copyCommonFields(transferRequestTargetSearch, ['transferRequest']);
    return {
      ...commonFields,
      transferRequestId: transferRequestTargetSearch.transferRequest?.id,
    };
  }

  #loadTransferRequest(transferRequestId: number, injector: Injector) {
    return derivedAsync(() => this.#transferRequestService.getTransferRequest$(transferRequestId, injector), {injector: injector});
  }

  #loadTargetWarehouse(warehouseId: number, injector: Injector) {
    return derivedAsync(() => this.#warehouseService.getWarehouse$(warehouseId), {injector: injector});
  }

  #createCache(): TransferRequestTargetServiceCache {
    const warehouseCache = new Cache<number, Signal<Warehouse | undefined>>();
    const transferRequestCache = new Cache<number, Signal<TransferRequest | undefined>>();

    return {
      transferRequestCache: transferRequestCache,
      warehouseCache: warehouseCache,
    };
  }
}
