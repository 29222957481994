import {Component, model} from '@angular/core';
import {MovementType} from "@model/movement-type";
import {LanguageDirective} from "@directives/language.directive";
import {NgIf} from "@angular/common";

@Component({
  selector: 'foodbank-movement-type',
  templateUrl: './movement-type.component.html',
  styleUrls: ['./movement-type.component.scss'],
  standalone: true,
  imports: [
    LanguageDirective,
    NgIf
  ]
})
export class MovementTypeComponent {

  ngModel = model<MovementType>();
}
