@let mvt = movement() ;
@if (mvt) {
  <div class="card p-fluid">
    <div class="field grid">
      <label class="col-12 mb-2 md:col-2 md:mb-0">Movement*</label>
      <foodbank-movement-type-single-selection ngDefaultControl class="col-12 md:col-10" [movementTypeSearch]="movementTypeSearch()" [ngModel]="(mvt.movementType$ | async) || undefined" (ngModelChange)="handleMovementTypeSelected(mvt, $event)"/>
    </div>

    @if (!onlyUpdateQuantity()) {
      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0">Supplier*</label>
        <foodbank-supplier-selection ngDefaultControl class="col-12 md:col-10" id="supplier" [supplierSearch]="{}" (ngModelChange)="handleSupplierSelected(mvt, $event)"/>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0">Article*</label>
        <foodbank-article-selection ngDefaultControl class="col-12 md:col-10" [ngModel]="(mvt.article$ | async) || undefined" (ngModelChange)="handleArticleSelected(mvt, $event)" [disabled]="!(mvt.supplier$ | async)"/>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Comment</label>
        <div class="col-12 md:col-10">
          <input type="text" pInputText [(ngModel)]="mvt.comment">
        </div>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0">Warehouse*</label>
        @if (warehouseSearch()) {
          <foodbank-warehouse-selection class="col-12 md:col-10" [warehouseSearch]="warehouseSearch()!" [ngModel]="(mvt.warehouse$ | async) || undefined" (ngModelChange)="handleWarehouseSelected(mvt, $event)" ngDefaultControl/>
        }
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0">Organization*</label>
        <ng-container *ngIf="{}">
          <foodbank-organization-selection class="col-12 md:col-10" [organizationSearch]="{}" [ngModel]="(mvt.organization$ | async) || undefined" (ngModelChange)="handleOrganizationSelected(mvt, $event)" ngDefaultControl/>
        </ng-container>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Location</label>
        <div class="col-12 md:col-10">
          <input type="text" pInputText [(ngModel)]="mvt.location">
        </div>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Pallet</label>
        <div class="col-12 md:col-10">
          <foodbank-pallet-single-selection [selectedPallet]="(mvt.stockPallet$ | async) || undefined" (onPalletSelected)="handlePalletSelected(mvt, $event)" [palletSearch]="{withStock: true}" ngDefaultControl>
            >
          </foodbank-pallet-single-selection>
          <div *ngIf="(mvt.stock$ | async) as stock; else noStock">
            {{ stock.quantity }} kg in stock #{{ stock.id }}
          </div>
        </div>
        <ng-template #noStock>
          <div>
            No stock found for pallet, please select another.
          </div>
        </ng-template>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Date*</label>
        <foodbank-date-picker ngDefaultControl class="col-12 md:col-10" [ngModel]="date" [showSelectionOptions]="false" (onSelect)="handleDateSelected(mvt, $event)"/>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Supplier batch</label>
        <div class="col-12 md:col-10">
          <input type="text" pInputText [(ngModel)]="mvt.supplierBatchName">
        </div>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>DLC*</label>
        <foodbank-date-picker ngDefaultControl class="col-12 md:col-10" [ngModel]="bestBeforeDate" [showSelectionOptions]="false" (onSelect)="handleBestBeforeDateSelected(mvt, $event)"/>
      </div>

      <div class="field grid">
        <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>DDM*</label>
        <foodbank-date-picker ngDefaultControl class="col-12 md:col-10" [ngModel]="expirationDate" [showSelectionOptions]="false" (onSelect)="handleExpirationDateSelected(mvt, $event)"/>
      </div>
    }
    <div class="field grid">
      <label class="col-12 mb-2 md:col-2 md:mb-0" i18n>Weight (kg)*</label>
      <div class="col-12 md:col-10">
        <input type="number" pInputText [(ngModel)]="mvt.quantity" min="0">
      </div>
    </div>

    @if (onlyUpdateQuantity()) {
      <div *ngIf="(mvt.stock$ | async) as stock">
        <div class="flex align-items-center mt-3 mb-3" *ngIf="(mvt.stockPallet$ | async) as pallet">
          <span>{{ stock.quantity }} kg on pallet</span><foodbank-pallet [pallet]="pallet" class="ml-1" />
        </div>
      </div>
    }

    <div class="field grid">
      <div class="col-offset-3 col-3">
        <p-button label="Save" icon="pi pi-check" styleClass="p-button-primary" (onClick)="saveAndClose(mvt)"/>
      </div>
      <div class="col-3">
        <p-button label="Close" icon="pi pi-times" styleClass="p-button-secondary" (onClick)="close()"/>
      </div>
    </div>
  </div>
}
