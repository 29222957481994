@let selectedTransferRequest = transferRequest() ;
@if (selectedTransferRequest) {
  <p-tabView [(activeIndex)]="activeTabIndex">

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span pDroppable (onDrop)="removeSelectedTransferRequestItems()">
          <span class="fa-solid fa-warehouse tab-icon mr-1"></span>Warehouses
        </span>
      </ng-template>
      <foodbank-transfer-request-target-list [transferRequest]="selectedTransferRequest"/>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span pDroppable (onDrop)="removeSelectedTransferRequestItems()">
          <span class="fa-solid fa-boxes-stacked tab-icon mr-1"></span>Stock
        </span>
      </ng-template>
      @let sourceWarehouse = selectedTransferRequest.warehouse() ;
      @if (sourceWarehouse) {
        <foodbank-stock-list [selectedWarehouses]="[sourceWarehouse]"
                             [embedded]="true"
                             [unrestrictedCompany]="true"
                             (onStockDragStart)="startStockDrag($event)"
        />
      }
      <ng-template #noSelectedWarehouse>
        <p>Select a warehouse</p>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel>
      <ng-template pTemplate="header">
        <span pDroppable (onDrop)="createTransferRequestItemsFromDraggedStock()">
          <span class="fa-solid fa-truck-fast tab-icon mr-1"></span>
          <span class="mr-1">Transfer request items</span>
        </span>
      </ng-template>

      <foodbank-transfer-request-item-list [transferRequest]="selectedTransferRequest"/>
    </p-tabPanel>

  </p-tabView>
}
