<div class="flex justify-content-between text-red-800">
  <div class="flex align-items-center" (click)="openPrintLabelDialog()">
    <i *ngIf="!showIdOnly" class="fa-solid fa-pallet -mt-2 mr-1"></i>{{ palletId }}
  </div>
  <div>
    <i *ngIf="showHistory" class="pi pi-history ml-4" (click)="palletHistory.toggle($event)"></i>
    <p-overlayPanel #palletHistory [style]="{ width: '50%', 'min-width': '770px' }">
      <ng-template pTemplate="content">
        <foodbank-movement-list [movementSearch]="{ pallet }" />
      </ng-template>
    </p-overlayPanel>
  </div>
</div>
