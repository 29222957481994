import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateSelectionMode, DateSelectionModeComponent} from '@components/date/date-selection-mode/date-selection-mode.component';
import {CalendarModule} from "primeng/calendar";
import {FormsModule} from "@angular/forms";
import { NgIf } from "@angular/common";

export interface FoodbankDatePickerEvent {
  value: Date | Date[] | undefined;
  utcValue: Date | Date[] | undefined;
  selectionMode: DateSelectionMode;
}

@Component({
  selector: 'foodbank-date-picker',
  templateUrl: './date-picker.component.html',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule,
    DateSelectionModeComponent,
    NgIf
  ]
})
export class DatePickerComponent implements OnInit {

  @Input() ngModel: Date | Date[] | undefined;
  @Output() onSelect: EventEmitter<FoodbankDatePickerEvent> = new EventEmitter<FoodbankDatePickerEvent>();
  @Output() ngModelChange: EventEmitter<Date | Date[] | undefined> = new EventEmitter<Date | Date[] | undefined>();

  @Input() inputId: string | undefined;
  @Input() showSelectionOptions: boolean = true;
  @Input() required: boolean = true;
  @Input() showClear: boolean = false;
  @Input() disabled: boolean = false;
  @Input() placeholder: string = "";
  @Input() showButtonBar = false;

  dateSelectionMode: DateSelectionMode;
  defaultDateSelectionMode: string = 'single';

  ngOnInit(): void {
    const key = this.getSelectionModePrefKey();
    const dateSelectionMode = !!this.inputId && localStorage.getItem(key) || this.defaultDateSelectionMode;
    this.dateSelectionMode = dateSelectionMode as DateSelectionMode;
  }

  dateRangeChanged(value: Date | Date[] | undefined) {
    let utcValue = value;

    if (value instanceof Date) {
      //convert to UTC
      utcValue = DatePickerComponent.convertToUTC(value);
      this.ngModelChange.emit(utcValue);
    }

    if (utcValue instanceof Array) {
      if (utcValue?.filter(v => !!v).length < 2) {
        return;
      }

      utcValue = utcValue.map(value => DatePickerComponent.convertToUTC(value));
    }

    this.emit({ value, utcValue, selectionMode: this.dateSelectionMode });
  }

  private static convertToUTC(value: Date) {
    const utcValue = new Date(value);
    utcValue.setMinutes(utcValue.getMinutes() - utcValue.getTimezoneOffset());
    return utcValue;
  }

  private emit(event: FoodbankDatePickerEvent) {
    this.onSelect.emit(event);
    this.ngModelChange.emit(event.utcValue);
  }

  saveDateSelectionPreferences(value: DateSelectionMode) {
    this.dateSelectionMode = value;
    if (!!this.inputId) {
      const key = this.getSelectionModePrefKey();

      localStorage.setItem(key, value?.toString() || this.defaultDateSelectionMode);
    }
  }

  private getSelectionModePrefKey() {
    return this.inputId + '.selection.mode';
  }
}
