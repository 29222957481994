<p-dropdown [(ngModel)]="ngModel"
            [options]="warehousesPage()?.content || []"
            optionLabel="name"
            placeholder="None"
            [filter]="true"
            [required]="required()"
            filterMatchMode="contains"
            filterBy="id,name"
            [showClear]="showClear()"
            dataKey="id"
            appendTo="body">
    <ng-template pTemplate="selectedItem">
        <foodbank-warehouse ngDefaultControl [ngModel]="ngModel()" />
    </ng-template>
    <ng-template let-warehouse pTemplate="item">
        <foodbank-warehouse ngDefaultControl [ngModel]="warehouse" />
    </ng-template>
</p-dropdown>
