import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

@Component({
  selector: 'foodbank-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnChanges {

  @Input()
  value?: number;
  @Input()
  decimals = 2;
  @Input()
  decimalsColor?: string;

  integerPart?: number;
  integerPartStr?: string;
  decimalPart?: number;
  decimalPartStr?: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.value != undefined) {
      const fixedValue = this.value.toFixed(this.decimals).split('.');

      this.integerPart = parseInt(fixedValue[0]);
      this.decimalPart = parseInt(fixedValue[1]);
      this.integerPartStr = fixedValue[0];
      this.decimalPartStr = fixedValue[1];
    } else {
      this.integerPartStr = undefined;
      this.decimalPartStr = undefined;
    }
  }

}
