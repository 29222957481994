import {Component, OnInit} from '@angular/core';
import {UserService} from '@services/user.service';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  mergeMap,
  Observable,
  shareReplay
} from "rxjs";
import {TableLazyLoadEvent} from "primeng/table";
import {Page} from '@typedefs/page';
import {FilterMetadata} from 'primeng/api';
import {WarehouseSearch} from '@model/search/warehouse-search';
import {UserSearch} from '@model/search/user-search';
import {DEFAULT_ROWS_PER_PAGE, PaginationService} from '@services/pagination.service';
import {FeadPlanningPagination} from '@services/fead-planning-summary.service';
import {User} from '@model/user';
import {Warehouse} from '@model/warehouse';

type TableFilters = { [p: string]: FilterMetadata | FilterMetadata[] };

@Component({
  selector: 'users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  userSearch$!: Observable<UserSearch>;
  userPage$?: Observable<Page<User>>;
  warehouseSearch$!: Observable<WarehouseSearch>;

  pagination$: BehaviorSubject<FeadPlanningPagination> = new BehaviorSubject({page: 0, size: DEFAULT_ROWS_PER_PAGE});
  selectedWarehouses$: BehaviorSubject<Warehouse[]> = new BehaviorSubject<Warehouse[]>([]);
  filters: TableFilters = { warehouses: { value: [] } };

  constructor(private userService: UserService,
              private paginationService: PaginationService) {
  }

  ngOnInit(): void {
    const currentUser$ = this.userService.getCurrentUser$();
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    const company$ = currentUser$.pipe(
      mergeMap(user => user.company$),
      shareReplay(),
    );
    this.warehouseSearch$ = company$.pipe(
      map(company => ({
        company: company,
      } as WarehouseSearch)),
      shareReplay(),
    )

    defaultWarehouse$
      .subscribe(defaultWarehouse => {
        const warehouses = [defaultWarehouse as Warehouse];
        this.filters['warehouses'] = { value: warehouses };
        this.selectedWarehouses$.next(warehouses);
      });

    this.userSearch$ = combineLatest([company$, this.selectedWarehouses$]).pipe(
      map(([company, selectedWarehouses]) => ({
          company: company,
          warehouseSearch: {
            warehouses: selectedWarehouses
          }
        } as UserSearch)),
      shareReplay(),
    )
    this.userPage$ = combineLatest([this.pagination$, this.userSearch$]).pipe(
      distinctUntilChanged(([pagination1, userSearch1], [pagination2, userSearch2]) => this.isSameUserSearch(pagination1, pagination2) && this.isSameUserSearch(userSearch1, userSearch2)),
      mergeMap(([pagination, userSearch]) => this.userService.findUsers(userSearch, pagination)),
      shareReplay(),
    );
  }

  getWarehousesFilter(event: TableLazyLoadEvent): Warehouse[] {
    const filters = event.filters;
    const warehouseFilter = <FilterMetadata>filters?.['warehouses'];
    return warehouseFilter?.value || [];
  }

  loadUsers(event: TableLazyLoadEvent) {
    const selectedWarehouses = this.getWarehousesFilter(event);
    const pagination = this.paginationService.getTablePagination(event);
    this.selectedWarehouses$.next(selectedWarehouses);
    this.pagination$.next(pagination);
  }

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  identity(user: any): User {
    return user;
  }

  private isSameUserSearch<T>(value1: T, value2: T) {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }
}
