<p-multiSelect *foodbankLanguage="'fr'" [(ngModel)]="ngModel" [options]="(movementTypes$ | async) || []" placeholder="Any" filterMatchMode="contains"
               (onChange)="select($event.value)" (onClear)="clear()" [showClear]="true" filterBy="languageNameMap.FRENCH" appendTo="body" dataKey="movementKind">
  <ng-template let-selectedItems pTemplate="selectedItems">
    <ng-container *ngFor="let selectedMovementType of selectedItems">
      {{ selectedMovementType.languageNameMap.FRENCH }}
      <br/>
    </ng-container>
    <ng-container *ngIf="!ngModel() || ngModel().length === 0" i18n>Any</ng-container>
  </ng-template>
  <ng-template let-MovementType pTemplate="item">
    {{ MovementType.languageNameMap.FRENCH }}
  </ng-template>
</p-multiSelect>

<p-multiSelect *foodbankLanguage="'nl'" [(ngModel)]="ngModel" [options]="(movementTypes$ | async) || []" placeholder="Any" filterMatchMode="contains"
               (onChange)="select($event.value)" (onClear)="clear()" [showClear]="true" filterBy="languageNameMap.DUTCH" appendTo="body" dataKey="movementKind">
  <ng-template let-selectedItems pTemplate="selectedItems">
    <ng-container *ngFor="let selectedMovementType of selectedItems">
      {{ selectedMovementType.languageNameMap.DUTCH }}
      <br/>
    </ng-container>
    <ng-container *ngIf="!ngModel() || ngModel().length === 0" i18n>Any</ng-container>
  </ng-template>
  <ng-template let-MovementType pTemplate="item">
    {{ MovementType.languageNameMap.DUTCH }}
  </ng-template>
</p-multiSelect>
