<div *ngIf="stock" class="foodbank-pallet">

  <div class="pallet-details-barcode text-center">
    <ngx-barcode6 *ngIf="barcodeValue"
                  [bc-format]="'CODE128'"
                  [bc-value]="barcodeValue"
                  [bc-display-value]="true"
                  [bc-height]="50"
                  [bc-width]="5"
                  [bc-font-size]="15"
                  [bc-margin-bottom]="0"
                  [bc-text-margin]="0"
                  bc-element-type="img" />
  </div>

  <p class="pallet-details-pallet-id text-center border-1">{{ stock.palletId }}</p>

  <table>
    <tbody>
    <tr class="text-xl font-medium">
      <td i18n>Article</td>
      <td>{{ (article$ | async)?.id }}</td>
    </tr>
    <tr class="text-xl font-medium">
      <td colspan="2">
        <i class="fi fi-fr"></i> {{ (article$ | async)?.nameFr }}
      </td>
    </tr>
    <tr class="text-xl font-medium">
      <td colspan="2">
        <i class="fi fi-nl"></i> {{ (article$ | async)?.nameNl }}
      </td>
    </tr>
    <tr class="text-xl font-medium">
      <td colspan="2">{{ stock.fullDescription }}</td>
    </tr>
    <tr>
      <td i18n>Supplier</td>
      <td>
        <foodbank-supplier ngDefaultControl [ngModel]="(supplier$ | async) || undefined" [showId]="false" />
      </td>
    </tr>
    <tr>
      <td i18n>Internal Lot</td>
      <td>{{ stock.internalBatch }}</td>
    </tr>
    <tr>
      <td i18n>Supplier Lot</td>
      <td>{{ stock.supplierBatch }}</td>
    </tr>
    <tr class="text-xl font-medium">
      <td i18n>Weight (Kg)</td>
      <td>{{ (summary$ | async)?.netWeight | number: '1.2-2' }}</td>
    </tr>
    <tr>
      <td i18n>Gross weight (Kg)</td>
      <td>{{ (summary$ | async)?.grossWeight | number: '1.2-2' }}</td>
    </tr>
    <tr>
      <td i18n>Unit weight (gr)</td>
      <td>{{ stock.unitWeight | number: '1.0-0' }}</td>
    </tr>
    <tr>
      <td i18n>Units per parcel</td>
      <td>{{ stock.unitsPerParcel }}</td>
    </tr>
    <tr>
      <td i18n>Parcels</td>
      <td>{{ (summary$ | async)?.parcelCount }}</td>
    </tr>
    <tr>
      <td i18n>Total units</td>
      <td>{{ (summary$ | async)?.unitCount }}</td>
    </tr>
    <tr>
      <td i18n>Best before date</td>
      <td>{{ stock.bestBeforeDate | date: 'dd/MM/yyyy' }}</td>
    </tr>
    <tr>
      <td i18n>Expiration date</td>
      <td>{{ stock.expirationDate | date: 'dd/MM/yyyy' }}</td>
    </tr>
    <tr class="text-xl font-medium">
      <td i18n>Location</td>
      <td>{{ stock.location }}</td>
    </tr>
    <tr>
      <td i18n>Pallet type</td>
      <td>
        <foodbank-pallet-type *ngIf="(stock.pallet$ | async)?.palletType$ | async as palletType" [palletType]="palletType" [showId]="false" />
      </td>
    </tr>
    <tr>
      <td i18n>Category</td>
      <td>
        <foodbank-article-category [articleCategory]="articleCategory$ | async" [showId]="false" />
      </td>
    </tr>
    </tbody>
  </table>

  <foodbank-movement-list *ngIf="stock.pallet$ | async as pallet" [movementSearch]="{ pallet }" [printMode]="true" />

</div>
