@let page = transferRequestTargetPage() ;
@let content = page?.content || [] ;
<p-table #dt
         [value]="content"
         responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         [totalRecords]="page?.totalElements || 0"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoadEvent($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [autoLayout]="true"
         dataKey="id"
         selectionMode="single"
         [multiSortMeta]="[{ field: 'TARGET_WAREHOUSE', order: 1 }]"
>
  <ng-template pTemplate="header">
    <tr style="vertical-align: bottom">
      <th i18n>
        Date
        <p-sortIcon field="DISPATCH_DATE"/>
      </th>
      <th i18n>
        Target company
        <p-sortIcon field="TARGET_COMPANY"/>
      </th>
      <th i18n>
        Target warehouse
        <p-sortIcon field="TARGET_WAREHOUSE"/>
      </th>
      <th i18n>
        Comment
      </th>
    </tr>
    <tr>
      <th>
      </th>
      <th>
      </th>
      <th>
      </th>
      <th>
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-row>
    @let transferRequestTarget = typing(row) ;
    <tr>
      <td style="width: 100px">
        {{ transferRequestTarget.dispatchDate | date: 'dd/MM/yyyy' }}
      </td>
      <td>
        <foodbank-company ngDefaultControl [ngModel]="(transferRequestTarget.targetWarehouse()?.company$ | async) || undefined"/>
      </td>
      <td>
        <foodbank-warehouse ngDefaultControl [ngModel]="transferRequestTarget.targetWarehouse()"/>
      </td>
      <td style="width: 100px">
        {{ transferRequestTarget.comment }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <span i18n>No transfer requests found.</span>
  </ng-template>
</p-table>
