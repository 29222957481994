import {inject, Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of, shareReplay} from "rxjs";
import {CompanyDto, CompanySearchDto, TransferRequestSearchDto, WarehouseDto} from '@typedefs/stock-rest';
import {environment} from '@environments/environment';
import {Warehouse} from '@model/warehouse';
import {Page} from "@typedefs/page";
import {Pagination} from "@services/pagination";
import {CompanySearch} from "@model/search/company-search";
import {Company} from "@model/company";
import {TransferRequestService} from "@services/transfer-request.service";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  #httpClient = inject(HttpClient);
  #injector = inject(Injector);

  public getCompany$(id: string): Observable<Company> {
    return this.#httpClient.get<CompanyDto>(`${environment.apiUrl}/companies/${id}`)
      .pipe(
        map(company => this.mapToCompany(company)),
        shareReplay()
      );
  }

  public findCompanies$(companySearch: CompanySearch, pagination: Pagination): Observable<Page<Company> | undefined> {
    const companySearchDto = this.mapToCompanySearchDto(companySearch ?? {});

    return this.#httpClient.post<Page<CompanyDto>>(`${environment.apiUrl}/companies/search`, companySearchDto, {params: pagination})
      .pipe(map(companyDtoPage => {
        const companies: Company[] = companyDtoPage.content.map(companyDto => this.mapToCompany(companyDto));
        return {
          ...companyDtoPage,
          content: companies,
        }
      }));
  }

  public mapToCompany(companyDto: CompanyDto): Company {
    const warehouse$ = companyDto.defaultWarehouseId ? this.getWarehouse$(companyDto.defaultWarehouseId) : of(undefined);
    return {
      ...companyDto,
      warehouse$
    }
  }

  public mapToCompanySearchDto(companySearch: CompanySearch): CompanySearchDto {
    return {
      ...companySearch,
      transferRequestSearchDto: this.loadTransferRequestSearchDto(companySearch),
    };
  }

  private getWarehouse$(id: number): Observable<Warehouse> {
    return this.#httpClient.get<WarehouseDto>(`${environment.apiUrl}/warehouses/${id}`)
      .pipe(
        map(warehouse => this.loadWarehouse(warehouse)),
        shareReplay()
      );
  }

  private loadWarehouse(warehouseDto: WarehouseDto): Warehouse {
    return {
      ...warehouseDto,
      company$: this.getCompany$(warehouseDto.companyId)
    }
  }

  private loadTransferRequestSearchDto(companySearch: CompanySearch): TransferRequestSearchDto | undefined {
    const transferRequestService = this.#injector.get(TransferRequestService);
    return companySearch.transferRequestSearch ? transferRequestService.mapToTransferRequestSearchDto(companySearch.transferRequestSearch) : undefined;
  }

}
