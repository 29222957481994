import {inject, Injectable, Injector, Signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {WarehouseService} from './warehouse.service';
import {TransferRequestDto, TransferRequestItemGroupDto, TransferRequestSearchDto} from '@typedefs/stock-rest';
import {CompanyService} from '@services/company.service';
import {derivedAsync} from "ngxtension/derived-async";
import {TransferRequest} from "@model/transfer-request";
import {Company} from "@model/company";
import {User} from "@model/user";
import {UserService} from "@services/user.service";
import {TransferRequestSearch} from "@model/search/transfer-request-search";
import {copyCommonFields} from "@model/mapping-utils";
import {Stock} from "@model/stock";

@Injectable({
  providedIn: 'root'
})
export class TransferRequestService {

  #httpClient = inject(HttpClient);
  #warehouseService = inject(WarehouseService);
  #companyService = inject(CompanyService);
  #userService = inject(UserService);

  public getTransferRequest$(transferRequestId: number, injector: Injector): Observable<TransferRequest> {
    return this.#httpClient.get<TransferRequestDto>(`${environment.apiUrl}/transfer/request/${transferRequestId}`)
      .pipe(
        map(transferRequestDto => this.mapToTransferRequest(transferRequestDto, injector)),
      );
  }

  public findTransferRequests$(transferRequestSearch: TransferRequestSearch, pagination: Pagination, injector: Injector): Observable<Page<TransferRequest> | undefined> {
    const transferRequestSearchDto = this.mapToTransferRequestSearchDto(transferRequestSearch);

    return this.#httpClient.post<Page<TransferRequestDto>>(`${environment.apiUrl}/transfer/request/search`, transferRequestSearchDto, {params: pagination})
      .pipe(map(transferRequestDtoPage => {
        const transferRequests: TransferRequest[] = transferRequestDtoPage.content.map(transferRequestDto => this.mapToTransferRequest(transferRequestDto, injector));
        return {
          ...transferRequestDtoPage,
          content: transferRequests
        }
      }));
  }

  public createTransferRequestItemsFromStocks(transferRequest: TransferRequest, stocks: Stock[], injector: Injector): Observable<TransferRequest> {
    return this.#httpClient.post<Page<TransferRequestItemGroupDto[]>>(`${environment.apiUrl}/stocks/transfer/request/${transferRequest.id}/item`, stocks.map(stock => stock.id), {})
      .pipe(map(_ => transferRequest));
  }

  public mapToTransferRequest(transferRequestDto: TransferRequestDto, injector: Injector): TransferRequest {
    return {
      ...transferRequestDto,
      company: this.loadCompany(transferRequestDto.companyId, injector),
      warehouse: this.loadWarehouse(transferRequestDto.warehouseId, injector),
      user: this.loadUser(transferRequestDto.userId, injector),
    };
  }

  public mapToTransferRequestDto(transferRequest: TransferRequest): Signal<TransferRequest | undefined> {
    return derivedAsync(() => {
        const companyId = transferRequest.company()?.id;
        const warehouseId = transferRequest.warehouse()?.id;
        const userId = transferRequest.user()?.id;

        const loaded = companyId && warehouseId && userId;

        return !loaded ? undefined : {
          ...transferRequest,
          companyId: companyId,
          warehouseId: warehouseId,
          userId: userId,
        };
      }
    );
  }

  public mapToTransferRequestSearchDto(transferRequestSearch: TransferRequestSearch): TransferRequestSearchDto {
    const commonFields: TransferRequestSearchDto | TransferRequestSearch = copyCommonFields(transferRequestSearch, ['company', 'warehouse']);
    return {
      ...commonFields,
      companyId: transferRequestSearch.company?.id,
      sourceWarehouseId: transferRequestSearch.warehouse?.id,
      exactTransferRequestId: transferRequestSearch.exactTransferRequest?.id,
    };
  }

  private loadWarehouse(warehouseId: number, injector: Injector) {
    return derivedAsync(() => this.#warehouseService.getWarehouse$(warehouseId), {injector: injector});
  }

  private loadCompany(companyId: string, injector: Injector): Signal<Company | undefined> {
    return derivedAsync(() => this.#companyService.getCompany$(companyId), {injector: injector})
  }

  private loadUser(userId: string, injector: Injector): Signal<User | undefined> {
    return derivedAsync(() => this.#userService.getUser$(userId), {injector: injector})
  }
}
