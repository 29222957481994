<p-table #dt [value]="(((editedStockPrevisionPage$ | async)?.content) || [])" responsiveLayout="scroll"
         [rows]="DEFAULT_ROWS_PER_PAGE"
         selectionMode="multiple"
         [totalRecords]="((editedStockPrevisionPage$ | async)?.totalElements || 0)"
         [globalFilterFields]="['depot']"
         [paginator]="true"
         [rowsPerPageOptions]="[15,25,50,100]"
         [showCurrentPageReport]="true"
         currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
         [lazy]="true"
         (onLazyLoad)="handleLazyLoad($event)"
         [rowHover]="true"
         [resizableColumns]="true"
         columnResizeMode="expand"
         sortMode="multiple"
         [(selection)]="selectedStockPrevisions"
         (selectionChange)="handleSelectionChange()"
         [autoLayout]="true"
         dataKey="id"
         [multiSortMeta]="[ { field: 'RECEPTION_DATE', order: -1 }, { field: 'WAREHOUSE', order: 1 }, { field: 'ARTICLE_CATEGORY_ID', order: 1 }, { field: 'ARTICLE_ID', order: 1 },  { field: 'SOURCE_WAREHOUSE', order: 1 },]"
         [styleClass]="tableSizeStyleClass"
>

  <ng-template pTemplate="caption">
    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <div class="grid">
        <h2 class="col-fixed m-0" i18n>Stock prevision</h2>
        <div class="grid mt-2 ml-2 align-items-center">
          <span>(</span>
          <label class="mr-2" for="show-validated" i18n>Show validated</label>
          <p-inputSwitch id="show-validated" class="mt-1 " [ngModel]="showValidated$ | async" (onChange)="filterByValidated($event)" inputId="show-validated" />
          <span>)</span>
        </div>
      </div>
      <span class="report-block mt-2 md:mt-0 p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" placeholder="Search..." [ngModel]="receptionIdStartsWith$ | async"
                 [ngModelOptions]="{ updateOn: 'change' }"
                 (ngModelChange)="filterByReceptionIdStartsWith($event)" class="w-full sm:w-auto"/>
      </span>
    </div>
    <div class="flex flex-column md:flex-row mt-5 justify-content-between">
      <div class="field">
        <p-button label="Mark verified ({{ selectedStockPrevisions.length}})" icon="pi pi-check" (onClick)="makSelectionVerified(true)" [disabled]="selectedStockPrevisions.length === 0" class="mr-2" />
        <p-button label="Reset ({{ selectedStockPrevisions.length}})" icon="pi pi-undo" (onClick)="makSelectionVerified(false)" [disabled]="selectedStockPrevisions.length === 0" class="mr-2" />
        <p-button label="Validate ({{ selectedStockPrevisions.length }})" pTooltip="Validate items and create reception" icon="pi pi-check" (onClick)="validate()" [disabled]="!selectedStockPrevisions.length" />
      </div>
      <div class="field">
        <foodbank-table-size [(tableStyleClass)]="tableSizeStyleClass"/>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="groupheader" let-stockPrevision let-expanded="expanded">
    <tr>
      <td colspan="19">
        <button type="button" pButton pRipple [pRowToggler]="stockPrevision" class="p-button-text p-button-rounded p-button-plain mr-2" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
        <span class="font-bold ml-2">{{ stockPrevision.receptAndDate }}</span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="header" let-value>
    <tr style="vertical-align: bottom">
      <th class="w-2rem">
        <p-tableHeaderCheckbox  />
      </th>
      <th>
        <span i18n>Id</span>
      </th>
      <th>
        <span i18n>Missing parcels</span>
      </th>
      <th pSortableColumn="ARTICLE_CATEGORY_ID" [hidden]="true">
        <span i18n>Category</span>
        <p-sortIcon field="ARTICLE_CATEGORY_ID" />
      </th>
      <th pSortableColumn="ARTICLE_ID" pResizableColumn>
        <span i18n>Article</span>
        <p-sortIcon field="ARTICLE_ID" />
      </th>
      <th pSortableColumn="SOURCE_WAREHOUSE" *ngIf="!sourceWarehouse">
        <span i18n>Source warehouse</span>
        <p-sortIcon field="SOURCE_WAREHOUSE" />
      </th>
      <th pSortableColumn="WAREHOUSE" *ngIf="!targetWarehouse">
        <span i18n>Target warehouse</span>
        <p-sortIcon field="WAREHOUSE" />
      </th>
      <th pSortableColumn="RECEPTION_DATE">
        <span i18n>Reception date</span>
        <p-sortIcon field="RECEPTION_DATE" />
      </th>
      <th pSortableColumn="QUANTITY">
        <span i18n>Quantity</span>
        <p-sortIcon field="QUANTITY" />
      </th>
      <th pSortableColumn="LOCATION">
        <span i18n>Location</span>
        <p-sortIcon field="LOCATION" />
      </th>
      <th [hidden]="true">
        <span i18n>DLC</span>
      </th>
      <th [hidden]="true">
        <span i18n>DLV</span>
      </th>
      <th>
        <span i18n>DLD</span>
      </th>
      <th>
        <span i18n>Unit Weight</span>
      </th>
      <th>
        <span i18n>Full description</span>
      </th>
      <th>
        <span i18n>Units per parcel</span>
      </th>
      <th>
        <span i18n>Unit Bruto Weight</span>
      </th>
      <th>
        <span i18n>Recept</span>
      </th>
    </tr>
    <tr>
      <th></th>
      <th></th>
      <th></th>
      <th [hidden]="true">
        <p-columnFilter field="articleCategories" matchMode="in" [showMenu]="false" [hidden]="true">
          <ng-template pTemplate="filter" let-articleCategory let-filter="filterCallback">
            <foodbank-article-category-selection [selectedArticleCategories]="articleCategory"
              [articleCategorySearch]="(filterArticleCategorySearch$ | async) || {}"
              (onArticleCategorySelected)="filterByArticleCategories($event); filter($event)"
             />
          </ng-template>
        </p-columnFilter>
      </th>
      <th>
        <p-columnFilter field="articles" matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-article let-filter="filterCallback">
            <foodbank-article-multi-selection [selectedArticles]="article"
              [articleSearch]="(filterArticleSearch$ | async) || undefined"
              (onArticleSelected)="filterByArticles($event);; filter($event)"
             />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="!sourceWarehouse">
        <p-columnFilter matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-warehouses-selection [selectedWarehouses]="(effectiveSourceWarehouses$ | async) || []"
              [warehouseSearch]="(filterSourceWarehouseSearch$ | async) || {}"
              (onWarehousesSelected)="filterBySourceWarehouses($event); filter($event)"
             />
          </ng-template>
        </p-columnFilter>
      </th>
      <th *ngIf="!targetWarehouse">
        <p-columnFilter matchMode="in" [showMenu]="false">
          <ng-template pTemplate="filter" let-filter="filterCallback">
            <foodbank-warehouses-selection [selectedWarehouses]="(effectiveTargetWarehouses$ | async) || []"
              [warehouseSearch]="(filterTargetWarehouseSearch$ | async) || {}"
              (onWarehousesSelected)="filterByWarehouses($event); filter($event)"
             />
          </ng-template>
        </p-columnFilter>
      </th>
      <th></th>
      <th></th>
      <th></th>
      <th [hidden]="true">
      <th [hidden]="true">
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
      <th></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-stockPrevision>
    <ng-container *ngIf="identity(stockPrevision) as stockPrevision">
      <tr [class.accepted]="stockPrevision.missingParcels === 0" [class.missing-parcels]="stockPrevision.missingParcels && stockPrevision.missingParcels > 0">
        <td>
          <p-tableCheckbox [value]="stockPrevision" [disabled]="stockPrevision.validated" />
        </td>
        <td>
          {{ stockPrevision.id }}
        </td>
        <td pEditableColumn [pEditableColumnDisabled]="stockPrevision.validated" pEditableColumnField="missingParcels">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="number"
                     [(ngModel)]="stockPrevision.missingParcels"
                     (ngModelChange)="handleMissingParcelsEdited(stockPrevision)"
                     (keyup.enter)="handleMissingParcelsEdited(stockPrevision)"
                     [ngModelOptions]="{ updateOn: 'blur' }"
                     style="width: 5rem"
              />
            </ng-template>
            <ng-template pTemplate="output">
              <span [class.editable]="!stockPrevision.validated" [class.input-required]="stockPrevision.missingParcels == undefined">
                <span *ngIf="stockPrevision.missingParcels != null else dontKnow">
                  {{ stockPrevision.missingParcels }}
                </span>
              <ng-template #dontKnow>
                <span class="missing-parcels">?</span>
              </ng-template>
              </span>
            </ng-template>
          </p-cellEditor>
          <span [hidden]="stockPrevision.validated">
            <p-button
              pTooltip="This will confirm that the stock has been received without missing parcels."
              icon="pi pi-check"
              [text]="true"
              (onClick)="markVerified(stockPrevision, true)"
              [disabled]="stockPrevision.missingParcels === 0"
              class="tiny" />
            <p-button
              pTooltip="This will clear stock reception status (missing parcels will be unknown)."
              icon="pi pi-undo"
              [text]="true"
              (onClick)="markVerified(stockPrevision, false)"
              [disabled]="stockPrevision.missingParcels == null"
              class="tiny" />
          </span>
        </td>
        <td [hidden]="true">
          <!-- TODO: any should go, it's there because article$ is not always defined correctly (undefined?), still passes ?. but then fails type checking -->
          <foodbank-article-category [articleCategory]="(stockPrevision.article$ | async)?.articleCategory$ | async" />
        </td>
        <td class="white-space-normal">
          <foodbank-article ngDefaultControl [ngModel]="(stockPrevision.article$ | async) || undefined" />
        </td>
        <td *ngIf="!sourceWarehouse">
          <foodbank-warehouse ngDefaultControl [ngModel]="(stockPrevision.sourceWarehouse$ | async) || undefined" />
        </td>
        <td *ngIf="!targetWarehouse">
          <foodbank-warehouse ngDefaultControl [ngModel]="(stockPrevision.warehouse$ | async) || undefined" />
        </td>
        <td>
          {{ stockPrevision.receptionDate }}
        </td>
        <td class="aligned-quantity">
          {{ stockPrevision.quantity | number: '1.2-2' }}
        </td>
        <td pEditableColumn [pEditableColumnDisabled]="stockPrevision.validated" pEditableColumnField="location">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <ng-container *ngIf="(createLocationSearch$(stockPrevision) | async) as locationSearch">
                <foodbank-location-single-selection
                  ngDefaultControl
                  [locationSearch]="locationSearch"
                  [selectedLocation]="(stockPrevision.location$ | async) || undefined"
                  (onLocationSelected)="handleLocationEdited($event, stockPrevision)"/>
              </ng-container>
            </ng-template>
            <ng-template pTemplate="output">
              <span *ngIf="stockPrevision.location != null else dontKnow">
                  {{ stockPrevision.location }}
                </span>
              <ng-template #dontKnow>
                <span>?</span>
              </ng-template>
            </ng-template>
          </p-cellEditor>
        </td>
        <td [hidden]="true">
          {{ stockPrevision.expirationDate }}
        </td>
        <td [hidden]="true">
          {{ stockPrevision.bestBeforeDate }}
        </td>
        <td>
          {{ stockPrevision.deliverBeforeDate }}
        </td>
        <td class="aligned-quantity">
          {{ stockPrevision.unitWeight | number: '1.0-0' }}
        </td>
        <td>
          {{ stockPrevision.fullDescription }}
        </td>
        <td class="aligned-quantity">
          {{ stockPrevision.unitsPerParcel }}
        </td>
        <td class="aligned-quantity">
          {{ stockPrevision.unitGrossWeight | number: '1.0-0' }}
        </td>
        <td>
          {{ stockPrevision.receptionId }}
        </td>
      </tr>
    </ng-container>
  </ng-template>
</p-table>
