@let user = ngModel() ;
@if (user) {
    <span [pTooltip]="user.id">
      <i class="pi pi-user mr-1"></i>
      @if (showName()) {
        {{ user.name }}
      }
      @if (showId()) {
        ({{ user.id }})
      }
    </span>
}

