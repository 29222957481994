<p-calendar (ngModelChange)="dateRangeChanged($event)"
            [disabled]="disabled"
            [inputId]="inputId"
            [ngModel]="ngModel"
            [placeholder]="placeholder"
            [readonlyInput]="true"
            [required]="required"
            [selectionMode]="dateSelectionMode"
            [showButtonBar]="showButtonBar"
            [showClear]="showClear"
            appendTo="body"
            class="w-12"
            dateFormat="yy-mm-dd">
  <ng-template pTemplate="header">
    <foodbank-date-selection-mode *ngIf="showSelectionOptions" [dateSelectionMode]="dateSelectionMode" (dateSelectionModeChange)="saveDateSelectionPreferences($event)" />
  </ng-template>
</p-calendar>
