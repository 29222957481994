import {computed, inject, Injectable, Injector, Signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map, Observable} from 'rxjs';
import {Page} from '@typedefs/page';
import {environment} from '@environments/environment';
import {Pagination} from './pagination';
import {TransferRequestItemDto, TransferRequestItemGroupDto, TransferRequestItemSearchDto} from '@typedefs/stock-rest';
import {derivedAsync} from "ngxtension/derived-async";
import {copyCommonFields} from "@model/mapping-utils";
import {TransferRequestItem} from "@model/transfer-request-item";
import {TransferRequestTargetService} from "@services/transfer-request-target.service";
import {ArticleService} from "@services/article.service";
import {ReceptionService} from "@services/reception.service";
import {TransferRequestItemSearch} from "@model/search/transfer-request-item-search";
import {TransferRequestItemGroup} from "@model/transfer-request-item.group";
import {TransferRequestService} from "@services/transfer-request.service";
import {TransferRequestTarget} from "@model/transfer-request-target";
import {Cache} from "@util/cache";
import {TransferRequest} from "@model/transfer-request";
import {Reception} from "@model/reception";
import {Article} from "@model/article";
import {Warehouse} from "@model/warehouse";

interface TransferRequestItemServiceCache {
  receptionCache: Cache<number, Signal<Reception | undefined>>,
  articleCache: Cache<string, Signal<Article | undefined>>,
  transferRequestCache: Cache<number, Signal<TransferRequest | undefined>>,
  transferRequestTargetCache: Cache<number, Signal<TransferRequestTarget | undefined>>,
}

@Injectable({
  providedIn: 'root'
})
export class TransferRequestItemService {

  #httpClient = inject(HttpClient);
  #articleService = inject(ArticleService);
  #receptionService = inject(ReceptionService);
  #transferRequestService = inject(TransferRequestService);
  #transferRequestTargetService = inject(TransferRequestTargetService);

  public getTransferRequestItem$(transferRequestItemId: number, injector: Injector): Observable<TransferRequestItem> {
    const cache = this.#createCache();

    return this.#httpClient.get<TransferRequestItemDto>(`${environment.apiUrl}/transfer/request/Item/${transferRequestItemId}`)
      .pipe(
        map(transferRequestItemDto => this.#mapToTransferRequestItem(transferRequestItemDto, injector, cache)),
      );
  }

  public findTransferRequestItems$(transferRequestItemSearch: TransferRequestItemSearch, pagination: Pagination, injector: Injector): Observable<Page<TransferRequestItem> | undefined> {
    const transferRequestItemSearchDto = this.mapToTransferRequestItemSearchDto(transferRequestItemSearch);

    const cache = this.#createCache();

    return this.#httpClient.post<Page<TransferRequestItemDto>>(`${environment.apiUrl}/transfer/request/item/search`, transferRequestItemSearchDto, {params: pagination})
      .pipe(map(transferRequestItemDtoPage => {
        const transferRequestItemDtos: TransferRequestItem[] = transferRequestItemDtoPage.content.map(transferRequestItemDto => this.#mapToTransferRequestItem(transferRequestItemDto, injector, cache));
        return {
          ...transferRequestItemDtoPage,
          content: transferRequestItemDtos
        }
      }));
  }

  public findTransferRequestItemGroups$(transferRequestItemSearch: TransferRequestItemSearch, pagination: Pagination, injector: Injector): Observable<Page<TransferRequestItemGroup> | undefined> {
    const transferRequestItemSearchDto = this.mapToTransferRequestItemSearchDto(transferRequestItemSearch);

    const cache = this.#createCache();

    // return of();
    //
    return this.#httpClient.post<Page<TransferRequestItemGroupDto>>(`${environment.apiUrl}/transfer/request/item/groups/search`, transferRequestItemSearchDto, {params: pagination})
      .pipe(map(transferRequestItemGroupDtoPage => {
        const transferRequestItemGroups: TransferRequestItemGroup[] = transferRequestItemGroupDtoPage.content.map(transferRequestItemGroupDto => this.#mapToTransferRequestItemGroup(transferRequestItemGroupDto, injector, cache));
        return {
          ...transferRequestItemGroupDtoPage,
          content: transferRequestItemGroups
        }
      }));
  }

  public mapToTransferRequestItemSearchDto(transferRequestItemSearch: TransferRequestItemSearch): TransferRequestItemSearchDto {
    const commonFields: TransferRequestItemSearchDto | TransferRequestItemSearch = copyCommonFields(transferRequestItemSearch, ['transferRequest']);
    return {
      ...commonFields,
      transferRequestId: transferRequestItemSearch.transferRequest?.id,
      articleId: transferRequestItemSearch.article?.id,
      receptionId: transferRequestItemSearch.reception?.id,
    };
  }

  #mapToTransferRequestItem(transferRequestItemDto: TransferRequestItemDto, injector: Injector, cache: TransferRequestItemServiceCache) {
    return {
      ...transferRequestItemDto,
      article: cache.articleCache.computeIfAbsent(transferRequestItemDto.articleId, () => this.#loadArticle(transferRequestItemDto.articleId, injector)),
      reception: cache.receptionCache.computeIfAbsent(transferRequestItemDto.receptionId, () => this.#loadReception(transferRequestItemDto.receptionId, injector)),
      transferRequestTarget: cache.transferRequestTargetCache.computeIfAbsent(transferRequestItemDto.transferRequestTargetId, () => this.#loadTransferRequestTarget(transferRequestItemDto.transferRequestTargetId, injector)),
    }
  }

  #mapToTransferRequestItemGroup(transferRequestItemGroupDto: TransferRequestItemGroupDto, injector: Injector, cache: TransferRequestItemServiceCache): TransferRequestItemGroup {
    const warehouseTransferRequestItemMap = this.#getWarehouseTransferRequestItemMap(transferRequestItemGroupDto, injector, cache);

    return {
      ...transferRequestItemGroupDto,
      transferRequest: cache.transferRequestCache.computeIfAbsent(transferRequestItemGroupDto.transferRequestId, () => this.#loadTransferRequest(transferRequestItemGroupDto.transferRequestId, injector)),
      article: cache.articleCache.computeIfAbsent(transferRequestItemGroupDto.articleId, () => this.#loadArticle(transferRequestItemGroupDto.articleId, injector)),
      reception: cache.receptionCache.computeIfAbsent(transferRequestItemGroupDto.receptionId, () => this.#loadReception(transferRequestItemGroupDto.receptionId, injector)),
      warehouseTransferRequestItemMap: warehouseTransferRequestItemMap,
    }
  }

  #getWarehouseTransferRequestItemMap(transferRequestItemGroupDto: TransferRequestItemGroupDto, injector: Injector, cache: TransferRequestItemServiceCache) {
    const warehouseTransferRequestItems: TransferRequestItem[] = transferRequestItemGroupDto.transferRequestItemDtos.map(transferRequestItemDto => this.#mapToTransferRequestItem(transferRequestItemDto, injector, cache));
    return computed(() => {
      const warehouseIdItemEntries: ([number, TransferRequestItem])[] = warehouseTransferRequestItems
        .map(item => [item.transferRequestTarget(), item] as [TransferRequestTarget | undefined, TransferRequestItem])
        .filter(([target]) => !!target)
        .map(([target, item]) => [target!.targetWarehouse(), item] as [Warehouse | undefined, TransferRequestItem])
        .filter(([warehouse]) => !!warehouse)
        .map(([warehouse, item]) => [warehouse!.id, item]);
      return new Map<number, TransferRequestItem>(warehouseIdItemEntries);
    });
  }

  #loadTransferRequestTarget(transferRequestTargetId: number, injector: Injector) {
    return derivedAsync(() => this.#transferRequestTargetService.getTransferRequestTarget$(transferRequestTargetId, injector), {injector: injector});
  }

  #loadTransferRequest(transferRequestId: number, injector: Injector) {
    return derivedAsync(() => this.#transferRequestService.getTransferRequest$(transferRequestId, injector), {injector: injector});
  }

  #loadArticle(articleId: string, injector: Injector) {
    return derivedAsync(() => this.#articleService.getArticle$(articleId), {injector: injector});
  }

  #loadReception(receptionId: number, injector: Injector) {
    return derivedAsync(() => this.#receptionService.getReception$(receptionId), {injector: injector});
  }

  #createCache(): TransferRequestItemServiceCache {
    const articleCache = new Cache<string, Signal<Article | undefined>>();
    const receptionCache = new Cache<number, Signal<Reception | undefined>>();
    const transferRequestCache = new Cache<number, Signal<TransferRequest | undefined>>();
    const transferRequestTargetCache = new Cache<number, Signal<TransferRequestTarget | undefined>>();

    return {
      articleCache: articleCache,
      receptionCache: receptionCache,
      transferRequestCache: transferRequestCache,
      transferRequestTargetCache: transferRequestTargetCache,
    };
  }
}
