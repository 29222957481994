import {Component, inject, OnInit} from '@angular/core';
import {InterWarehouseRoute} from "@model/inter-warehouse-route";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeMap,
  Observable,
  shareReplay,
  switchMap,
  toArray
} from "rxjs";
import {Page} from "@typedefs/page";
import {DEFAULT_ROWS_PER_PAGE, isSamePagination, PaginationService} from "@services/pagination.service";
import {UserService} from "@services/user.service";
import {InterWarehouseTransferService} from "@services/inter-warehouse-transfer-service";
import {StockPrevisionSearch} from "@model/search/stock-prevision-search";
import {TableLazyLoadEvent} from "primeng/table";
import {PreparationPagination} from "@services/preparation.service";
import {Company} from "@model/company";
import {Warehouse} from "@model/warehouse";

@Component({
  selector: 'foodbank-inter-warehouse-reception',
  templateUrl: './inter-warehouse-reception.component.html',
  styleUrl: './inter-warehouse-reception.component.scss'
})
export class InterWarehouseReceptionComponent implements OnInit {

  protected readonly DEFAULT_ROWS_PER_PAGE = DEFAULT_ROWS_PER_PAGE;

  interWarehouseRoutePage$!: Observable<Page<InterWarehouseRoute>>;
  selectedInterWarehouseRoute$!: BehaviorSubject<InterWarehouseRoute | undefined>;
  forceShowInterWarehouseReceptions$ = new BehaviorSubject(false);
  interWarehouseReceptionsCollapsed$!: Observable<boolean>;

  pagination$!: BehaviorSubject<PreparationPagination>;
  private userService = inject(UserService);
  private paginationService = inject(PaginationService);

  private interWarehouseTransferService = inject(InterWarehouseTransferService);

  // first collapse should be ignored
  private firstCollapseEvent!: boolean;

  ngOnInit(): void {
    this.firstCollapseEvent = true;
    const userCompany$ = this.userService.getCurrentUserCompany$();
    const stockPrevisionSearch$: Observable<StockPrevisionSearch> = userCompany$.pipe(
      map(userCompany => this.getStockPrevisionSearch(userCompany)),
    );

    const pagination = this.paginationService.getDefaultPagination();
    this.pagination$ = new BehaviorSubject<PreparationPagination>(pagination);

    const pagination$ = this.pagination$.pipe(
      distinctUntilChanged(isSamePagination)
    );

    this.interWarehouseRoutePage$ = combineLatest([pagination$, stockPrevisionSearch$]).pipe(
      mergeMap(([pagination, stockPrevisionSearch]) => this.interWarehouseTransferService.findInterWarehouseRoutes(stockPrevisionSearch, pagination)),
      shareReplay(),
    );

    // default inter-warehouse route selection (overridden by manual selection)
    this.selectedInterWarehouseRoute$ = new BehaviorSubject<InterWarehouseRoute | undefined>(undefined);
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$();
    combineLatest([this.interWarehouseRoutePage$, defaultWarehouse$]).pipe(
      switchMap(([interWarehouseRoutePage, defaultWarehouse]) =>
        this.getDefaultInterWarehouseRoute$(interWarehouseRoutePage.content, defaultWarehouse)
      ),
    ).subscribe(defaultInterWarehouseRoute => {
      this.selectedInterWarehouseRoute$.next(defaultInterWarehouseRoute);
    });

    this.interWarehouseReceptionsCollapsed$ = combineLatest([this.selectedInterWarehouseRoute$, this.forceShowInterWarehouseReceptions$]).pipe(
      map(([selectedInterWarehouseRoute, forceShowInterWarehouseReceptions]) => !forceShowInterWarehouseReceptions && !!selectedInterWarehouseRoute),
    );
  }

  handleLazyLoad(event: TableLazyLoadEvent) {
    const pagination = this.paginationService.getTablePagination(event);
    this.pagination$.next(pagination);
  }

  handleSelectionChange(interWarehouseRoute?: InterWarehouseRoute) {
    this.selectedInterWarehouseRoute$.next(interWarehouseRoute);
    this.forceShowInterWarehouseReceptions$.next(false);
  }

  handlePanelCollapsed(collapsed?: boolean) {
    if (this.firstCollapseEvent) {
      // ignore first event, which seems to always be !collapsed
      this.firstCollapseEvent = false;
      return;
    }
    this.forceShowInterWarehouseReceptions$.next(!collapsed);
  }

  fixTyping(interWarehouseRoute: InterWarehouseRoute): InterWarehouseRoute {
    return interWarehouseRoute;
  }

  private getDefaultInterWarehouseRoute$(interWarehouseRoutes: InterWarehouseRoute[], defaultWarehouse?: Warehouse): Observable<InterWarehouseRoute | undefined> {
    return from(interWarehouseRoutes).pipe(
      mergeMap(interWarehouseRoute => interWarehouseRoute.targetWarehouse$.pipe(
        filter(targetWarehouse => this.isSelectedWarehouse(targetWarehouse, defaultWarehouse)),
        map(_ => interWarehouseRoute),
      )),
      toArray(),
      map(interWarehouseRoutes => this.getSingleElement(interWarehouseRoutes)),
    );
  }

  private getSingleElement<T>(array: T[]): T | undefined {
    return array.length === 1 ? array[0] : undefined;
  }

  private isSelectedWarehouse(warehouse: Warehouse, selectedWarehouse?: Warehouse) {
    return !selectedWarehouse || warehouse.id === selectedWarehouse.id;
  }

  private getStockPrevisionSearch(userCompany: Company): StockPrevisionSearch {
    return {
      warehouseSearch: {
        company: userCompany
      },
      validated: false,
    };
  }
}
