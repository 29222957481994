import { Component, computed, inject, Input, model, OnInit, Signal, signal } from '@angular/core';
import {Movement} from "@model/movement";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import { EMPTY, filter, map, Observable, of, switchMap } from "rxjs";
import {MovementService} from "@services/movement.service";
import { WarehouseSearch, warehouseSearchByCompany } from "@model/search/warehouse-search";
import {UserService} from "@services/user.service";
import { FilterMetadata, MessageService } from "primeng/api";
import {Warehouse} from "@model/warehouse";
import {Article} from "@model/article";
import {MovementType} from "@model/movement-type";
import {Supplier} from "@model/supplier";
import {FoodbankDatePickerEvent} from "@components/date/date-picker/date-picker.component";
import {MovementTypeService} from "@services/movement-type.service";
import {User} from "@model/user";
import {Stock} from "@model/stock";
import {Reception} from "@model/reception";
import {Organization} from "@model/organization";
import {StockPallet} from "@model/stock-pallet";
import {StockService} from "@services/stock.service";
import { MovementTypeSearch } from "@model/search/movement-type-search";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";

type TableFilters = { [p: string]: FilterMetadata | FilterMetadata[] };

@Component({
  selector: 'foodbank-new-movement-dialog',
  templateUrl: './movement-dialog.component.html',
  styleUrl: './movement-dialog.component.scss'
})
export class MovementDialogComponent implements OnInit {

  movementInput = model<Partial<Movement> | undefined>(undefined, { alias: 'movement' });

  movement: Signal<Partial<Movement> | undefined>;

  private movementService = inject(MovementService);
  private userService = inject(UserService);
  private movementTypeService = inject(MovementTypeService);
  private stockService = inject(StockService);
  private messageService = inject(MessageService);

  @Input()
  date = new Date();
  @Input()
  bestBeforeDate?: Date;
  @Input()
  expirationDate?: Date;

  warehouseSearch: Signal<WarehouseSearch | undefined>;

  filters: TableFilters = {};
  movementTypeSearch = signal<MovementTypeSearch>({
    movementKinds: MovementTypeService.ALLOWED_MOVEMENT_KINDS
  });

  onlyUpdateQuantity: Signal<boolean>;

  constructor(private ref: DynamicDialogRef, config: DynamicDialogConfig<Partial<Movement>>) {
    this.movement = computed(() => this.movementInput() ?? config.data);
    const currentUserCompany = this.userService.getCurrentUserCompany();
    this.warehouseSearch = computed(() => warehouseSearchByCompany(currentUserCompany()));

    this.onlyUpdateQuantity = toSignal(toObservable(this.movement).pipe(
      switchMap(movement => movement?.movementType$ ?? EMPTY),
      map(movementType => {
        return movementType?.movementKind === 'INVENTORY_PLUS' ||
          movementType?.movementKind === 'INVENTORY_MINUS' ||
          movementType?.movementKind === 'KITCHEN_OUT' ||
          movementType?.movementKind === 'DESTRUCTION' ||
          movementType?.movementKind === 'DONATION_TO_VOLUNTEERS';
      })
    ), { initialValue: false });
  }

  ngOnInit() {
    const defaultMovementType$ = this.movementTypeService.getMovementType$('INVENTORY_PLUS');
    const currentUserCompany$ = this.userService.getCurrentUserCompany$();
    const defaultWarehouse$ = this.userService.getDefaultWarehouse$().pipe(
      filter((warehouse): warehouse is Warehouse => warehouse !== undefined)
    );

    const movement: Partial<Movement> = this.movement() || {
      movementType$: defaultMovementType$,
      warehouse$: defaultWarehouse$,
      company$: currentUserCompany$,
      article$: of({} as Article),
      supplier$: of({} as Supplier),
      user$: of({} as User),
      stock$: of({} as Stock),
      reception$: of({} as Reception),
      organization$: of({} as Organization),
      stockPallet$: of({} as StockPallet),
    };
    this.movementInput.set(movement);


  }

  saveAndClose(movement: Partial<Movement>) {
    this.save(movement as Movement)
      .subscribe({
        next: _ => this.ref.close(movement),
        error: error => this.messageService.add({severity: 'error', summary: 'Error', detail: error?.error?.message ?? error?.message})
      });
  }

  close() {
    this.ref.close({});
  }

  save(movement: Movement): Observable<Movement> {
    const updatedMovement$ = this.movementService.saveMovement(movement)

    updatedMovement$.subscribe(movement => {
      this.movementInput.set(movement);
    })

    return updatedMovement$;
  }

  handleMovementTypeSelected(movement: Partial<Movement>, movementType?: MovementType) {
    const movementType$ = movementType ? of(movementType) : undefined;
    const updatedMovement = {
      ...movement,
      movementType$: movementType$
    };
    this.movementInput.set(updatedMovement);
  }

  handleSupplierSelected(movement: Partial<Movement>, supplier?: Supplier) {
    const updatedMovement = {
      ...movement,
      supplier$: of(supplier)
    };
    this.movementInput.set(updatedMovement);
  }

  handleArticleSelected(movement: Partial<Movement>, article: Article) {
    const updatedMovement = {
      ...movement,
      article$: of(article)
    };
    this.movementInput.set(updatedMovement);
  }

  handleWarehouseSelected(movement: Partial<Movement>, warehouse?: Warehouse) {
    const warehouse$ = warehouse ? of(warehouse) : undefined;
    const updatedMovement = {
      ...movement,
      warehouse$: warehouse$
    };
    this.movementInput.set(updatedMovement);
  }

  handleOrganizationSelected(movement: Partial<Movement>, organization: Organization) {
    const updatedMovement = {
      ...movement,
      organization$: of(organization)
    };
    this.movementInput.set(updatedMovement);
  }

  handleDateSelected(movement: Partial<Movement>, date: FoodbankDatePickerEvent) {
    this.date = date.value as Date;
    const updatedMovement = {
      ...movement,
      date: this.date
    };
    this.movementInput.set(updatedMovement);
  }

  handleBestBeforeDateSelected(movement: Partial<Movement>, bestBeforeDate: FoodbankDatePickerEvent) {
    this.bestBeforeDate = bestBeforeDate.value as Date;
    const updatedMovement = {
      ...movement,
      // bestBeforeDate: this.bestBeforeDate
    };
    this.movementInput.set(updatedMovement);
  }

  handleExpirationDateSelected(movement: Partial<Movement>, expirationDate: FoodbankDatePickerEvent) {
    this.expirationDate = expirationDate.value as Date;
    const updatedMovement = {
      ...movement,
      deliverBeforeDate: this.expirationDate,
    };
    this.movementInput.set(updatedMovement);
  }

  handlePalletSelected(movement: Partial<Movement>, stockPallet: StockPallet) {
    console.log('pal', stockPallet)
    const stock$ = stockPallet ? this.stockService.getStockByPallet$(stockPallet) : of(undefined);
    const updatedMovement: Partial<Movement> = {
      ...movement,
      stockPallet$: of(stockPallet),
      stock$: stock$,
    };
    this.movementInput.set(updatedMovement);
  }
}
