import {Component, EventEmitter, Input, Output} from '@angular/core';
import {RadioButtonClickEvent, RadioButtonModule} from 'primeng/radiobutton';
import {FormsModule} from "@angular/forms";

export type DateSelectionMode = 'single' | 'multiple' | 'range' | undefined;

@Component({
  selector: 'foodbank-date-selection-mode',
  templateUrl: './date-selection-mode.component.html',
  standalone: true,
  imports: [
    RadioButtonModule,
    FormsModule
  ]
})
export class DateSelectionModeComponent {

  @Input() dateSelectionMode: DateSelectionMode;

  @Output() dateSelectionModeChange: EventEmitter<DateSelectionMode> = new EventEmitter<DateSelectionMode>()

  constructor() { }

  onDateSelectionModeChanged($event: RadioButtonClickEvent) {
    this.dateSelectionMode = $event.value;
    this.dateSelectionModeChange.emit($event.value);
  }
}
