import {Component, input, model} from '@angular/core';
import {User} from "@model/user";
import {JsonPipe, NgIf} from "@angular/common";
import {TooltipModule} from "primeng/tooltip";

@Component({
  selector: 'foodbank-user',
  templateUrl: './user.component.html',
  standalone: true,
  imports: [
    NgIf,
    JsonPipe,
    TooltipModule
  ],
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
  ngModel = model<User>();
  showId = input(false);
  showName = input(true);
}
