<div class="layout-wrapper" [ngClass]="containerClass">
    <app-topbar  />
    <p-toast  />
    <div class="layout-sidebar">
        <app-sidebar  />
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet  />
        </div>
        <app-footer  />
    </div>
    <div class="layout-mask"></div>
</div>
