<p-multiSelect [ngModel]="selectedWarehouses"
               [options]="(warehouses$ | async) || []"
               placeholder="Any"
               filterMatchMode="contains"
               (onChange)="select($event.value)"
               (onClear)="clear()"
               [showClear]="true"
               appendTo="body"
               dataKey="id"
               optionLabel="id,name"
               [virtualScroll]="true"
               [virtualScrollItemSize]="expectedWarehouseItemSizeInPixels"
               [virtualScrollOptions]="virtualScrollOptions"
>
  <ng-template let-value pTemplate="selectedItems">
    <ng-container *ngFor="let selectedWarehouse of selectedWarehouses">
      <foodbank-warehouse ngDefaultControl [ngModel]="selectedWarehouse" />
      <br />
    </ng-container>
    <ng-container *ngIf="!selectedWarehouses || selectedWarehouses.length === 0" i18n>Any</ng-container>
  </ng-template>
  <ng-template let-warehouse pTemplate="item">
    <foodbank-warehouse ngDefaultControl [ngModel]="warehouse" />
  </ng-template>
</p-multiSelect>
