<h1>Fead planning</h1>
<div class="field grid">
  <label for="campaign" class="col-1" i18n>Campaign</label>
  <div class="col-3">
    <foodbank-fead-campaign-selection id="campaign"
      (onCampaignSelected)="selectCampaign($event)" />
  </div>
</div>
<div class="field grid">
  <label for="start-week" class="col-1" i18n>Start week</label>
  <div class="col">
    <input id="start-week" type="text" pInputText [(ngModel)]="feadPlanningSearch.startPeriod" formatWeek validWeek>
  </div>
</div>
<div class="field grid">
  <label for="end-week" class="col-1" i18n>End week</label>
  <div class="col">
    <input id="end-week" type="text" pInputText [(ngModel)]="feadPlanningSearch.endPeriod" formatWeek validWeek>
  </div>
</div>
<div class="field grid">
  <label for="end-week" class="col-fixed" i18n>Only relevant rows</label>
  <div class="col">
    <p-checkbox [(ngModel)]="relevantOnly" [binary]="true" />
  </div>
</div>
<div class="field grid">
  <div class="col">
    <foodbank-fead-planning-summary-table [feadPlanningSearch]="feadPlanningSearch"
      [relevantOnly]="relevantOnly" />
  </div>
</div>
