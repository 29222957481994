<p-tabView>
  <p-tabPanel>
    <ng-template pTemplate="header">
      <span class="pi pi-list tab-icon"></span>
      List
    </ng-template>
    <p-table #dt
             [value]="((preparationItemPage()?.content) || [])"
             responsiveLayout="scroll"
             [rows]="DEFAULT_ROWS_PER_PAGE"
             [totalRecords]="((preparationItemPage())?.totalElements || 0)"
             [globalFilterFields]="['depot']"
             [paginator]="true"
             [rowsPerPageOptions]="[15,25,50,100]"
             [showCurrentPageReport]="true"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             [lazy]="true"
             (onLazyLoad)="lazyLoad($event)"
             [rowHover]="true"
             [resizableColumns]="true"
             columnResizeMode="expand"
             sortMode="multiple"
             [autoLayout]="true"
             selectionMode="multiple"
             [(selection)]="selectedPreparationItems"
             dataKey="rank"
             [multiSortMeta]="[{ field: 'RANK', order: 1 },{ field: 'PREPARATION_WAREHOUSE', order: 1 },{ field: 'PREPARATION_LOCATION', order: 1 }]"
             styleClass="p-datatable-sm"
             [loading]="loading"
    >
      <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:align-items-center gap-1">
          <ng-container *ngIf="expedition()">
            <h2 *ngIf="fead()" class="m-0" i18n>ESF+ expedition</h2>
            <h2 *ngIf="!fead()" class="m-0" i18n>Expedition</h2>
            <p-button icon="pi pi-angle-double-down" label="Fill from stock" (onClick)="autoFillExpedition()"/>
            <input type="text" pInputText [(ngModel)]="expeditionComment" placeholder="<Please enter description of expedition>" size="50"/>
            <p-button icon="fa fa-truck" label="Generate expedition" (onClick)="generateExpedition()"/>
            <span *ngIf="selectedPreparationItems.length">
                for {{ selectedPreparationItems.length }} selected preparation item(s)
                    <p-button pTooltip="Clear selection."
                      icon="pi pi-times"
                      [text]="true"
                      (onClick)="clearSelection()"
                      class="tiny" />
              </span>
            <span *ngIf="!selectedPreparationItems.length">
                for {{ preparationItemPage()?.totalElements }} found preparation item(s) corresponding to current search criterias
              </span>
          </ng-container>
          <ng-container *ngIf="!expedition()">
            <h2 *ngIf="fead()" class="m-0" i18n>ESF+ preparation items</h2>
            <h2 *ngIf="!fead()" class="m-0" i18n>Preparation items</h2>
          </ng-container>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th colspan="2">
          </th>
          <th colspan="8">
            🧁 Preparation
          </th>
          <th colspan="3" *ngIf="expedition()">
            ✈️️ Expedition
          </th>
          <th>🌍 Delivered</th>
          <th>🏬 Stock</th>
        </tr>
        <tr style="vertical-align: bottom">
          <th>
            <p-tableHeaderCheckbox  />
          </th>
          <th style="text-align: center; width: 20rem" pSortableColumn="ORGANIZATION" pResizableColumn>
            <span i18n>Organization</span>
            <p-sortIcon field="ORGANIZATION" />
          </th>
          <th style="text-align: center" pSortableColumn="ARTICLE" pResizableColumn>
            <span i18n>Article</span>
            <p-sortIcon field="ARTICLE" />
          </th>
          <th pSortableColumn="PALLET" class="quantity-column">
            <span i18n>Pallet</span>
            <p-sortIcon field="PALLET" />
          </th>
          <th class="quantity-column">
            <span i18n>Location</span>
          </th>
          <th class="quantity-column">
            <span i18n pTooltip="Units">⚀ Units</span>
          </th>
          <th style="width: 1px; padding: 0"></th>
          <th class="quantity-column">
            <span i18n pTooltip="Unit weight">⚀ 🏋️</span>
          </th>
          <th style="width: 1px; padding: 0"></th>
          <th class="quantity-column">
            <span i18n>kg</span>
          </th>
          <th class="quantity-column">
            <span i18n pTooltip="Units per parcel">⚀ in 📦</span>
          </th>
          <th class="quantity-column" *ngIf="expedition()">
            <span i18n>🏋️</span>
          </th>
          <th class="quantity-column" *ngIf="expedition()">
            <span i18n>⚀ Units</span>
          </th>
          <th class="quantity-column" *ngIf="expedition()">
            <span i18n>📦 Parcels</span>
          </th>
          <th class="quantity-column">
            <span i18n>🏋️</span>
          </th>
          <th class="quantity-column">
            <span i18n>🏋️</span>
          </th>
        </tr>
        <tr>
          <th>
          </th>
          <th>
            <p-columnFilter field="organization" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter">
                <foodbank-organization-selection ngDefaultControl
                  [(ngModel)]="selectedOrganization"
                  [organizationSearch]="organizationSearch()"
                 />
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="article" matchMode="in" [showMenu]="false">
              <ng-template pTemplate="filter">
                <foodbank-article-selection id="article-filter" name="article-filter"
                                            [(ngModel)]="selectedArticle"
                                            [articleSearch]="articleSearch()"
                                            ngDefaultControl
                 />
              </ng-template>
            </p-columnFilter>
          </th>
          <th colspan="13" *ngIf="expedition()"></th>
          <th colspan="10" *ngIf="!expedition()"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-preparationItem>
        <ng-container *ngIf="identity(preparationItem) as preparationItem; let index">
          <tr>
            <td>
              <p-tableCheckbox [value]="preparationItem" />
            </td>
            <td class="white-space-normal">
              <foodbank-organization ngDefaultControl [organization]="(preparationItem.organization$ | async) || undefined" />
            </td>
            <td class="white-space-normal">
              <foodbank-article [showId]="true" [ngModel]="(preparationItem?.article$ | async) || undefined" ngDefaultControl />
              {{ preparationItem.fullDescription }}
            </td>
            <td class="aligned-quantity">
              {{ preparationItem.palletId }}
            </td>
            <td class="aligned-quantity">
              <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
                {{ stock.location }}
              </ng-container>
            </td>
            <td class="aligned-quantity">
              <ng-container>
                <foodbank-number [value]="preparationItem.numberOfUnits" [decimals]="0" />
              </ng-container>
            </td>
            <td>
              <i class="pi pi-times unit"></i>
            </td>
            <td class="aligned-quantity">
              <foodbank-number pTooltip="Unit weight (g)" [value]="preparationItem.unitWeight" [decimals]="0" />
              <span class="unit">g</span>
            </td>
            <td>
              =
            </td>

            <td class="aligned-quantity">
              <!-- weight -->
              <foodbank-number pTooltip="Selected weight (kg) for this preparation" [value]="preparationItem.quantity" [decimals]="3" />
              <span class="unit">kg</span>
            </td>

            <td class="aligned-quantity">
              <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
                <ng-container *ngIf="stock.unitsPerParcel >= 1">
                  {{ preparationItem.numberOfUnits / stock.unitsPerParcel | number: '1.0-0' }}
                </ng-container>
              </ng-container>
            </td>

            <td class="aligned-quantity" *ngIf="expedition()" pEditableColumn [pEditableColumnDisabled]="!!preparationItem.unitWeight" pEditableColumnField="dispatchWeight">
              <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
                      <input class="quantity-edit" pInputText type="number" id="preparation-item-{{index}}-dispatch-weight" name="preparation-item-{{index}}-dispatch-weight"
                             [ngModel]="preparationItem.dispatchWeight"
                             (ngModelChange)="updateDispatchWeight(preparationItem, stock, $event)"
                             min="0"
                             ngDefaultControl
                      />
                    </ng-container>
                  </ng-template>
                  <ng-template pTemplate="output">
                    <!-- # dispatched weight -->
                    <div [class.editable]="!preparationItem.unitWeight">
                      <foodbank-number *ngIf="preparationItem.dispatchWeight" [value]="preparationItem.dispatchWeight" [decimals]="3" />
                      <foodbank-number *ngIf="!preparationItem.dispatchWeight" class="estimated" [value]="calcDisplayDispatchWeight(preparationItem, stock)" [decimals]="0" />
                      <span class="unit">kg</span>
                    </div>
                  </ng-template>
                </p-cellEditor>
              </ng-container>
            </td>

            <td class="aligned-quantity" *ngIf="expedition()" pEditableColumn [pEditableColumnDisabled]="!preparationItem.unitWeight" pEditableColumnField="dispatchUnitCount">
              <!-- # dispatched units -->
              <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input class="quantity-edit" pInputText type="number" step="1" id="preparation-item-{{index}}-dispatch-unit-count" name="preparation-item-{{index}}-dispatch-unit-count"
                           [ngModel]="calcDisplayDispatchUnitCount(preparationItem, stock)"
                           (ngModelChange)="updateDispatchUnitCount(preparationItem, stock, $event)"
                           min="0"
                           ngDefaultControl
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    <div [class.editable]="!!preparationItem.unitWeight" class="quantity-edit-output">
                      <foodbank-number *ngIf="preparationItem.dispatchUnitCount" [value]="preparationItem.dispatchUnitCount" [decimals]="0" />
                      <ng-container *ngIf="calcDisplayDispatchUnitCount(preparationItem, stock) as estimatedDispatchUnitCount; else noEstimatedValue">
                        <foodbank-number *ngIf="!preparationItem.dispatchUnitCount" class="estimated" [value]="estimatedDispatchUnitCount" [decimals]="0" />
                      </ng-container>
                    </div>
                  </ng-template>
                </p-cellEditor>
              </ng-container>
            </td>

            <td class="aligned-quantity" *ngIf="expedition()" pEditableColumn [pEditableColumnDisabled]="!preparationItem.unitWeight" pEditableColumnField="dispatchParcelCount">
              <!-- # dispatched parcels -->
              <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input class="quantity-edit" pInputText type="number" step="1" id="preparation-item-{{index}}-dispatch-parcel-count" name="preparation-item-{{index}}-dispatch-parcel-count"
                           [ngModel]="calcDisplayDispatchParcelCount(preparationItem, stock)"
                           (ngModelChange)="updateDispatchParcelCount(preparationItem, stock, $event)"
                           min="0"
                           ngDefaultControl
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    <div [class.editable]="!!preparationItem.unitWeight" class="quantity-edit-output">
                      <foodbank-number *ngIf="preparationItem.dispatchParcelCount" [value]="preparationItem.dispatchParcelCount" [decimals]="1" />
                      <ng-container *ngIf="calcDisplayDispatchParcelCount(preparationItem, stock) as estimatedDispatchParcelCount; else noEstimatedValue">
                        <foodbank-number *ngIf="!preparationItem.dispatchParcelCount" class="estimated" [value]="estimatedDispatchParcelCount" [decimals]="1" decimalsColor="#aaaaaa" />
                      </ng-container>
                    </div>
                  </ng-template>
                </p-cellEditor>
              </ng-container>
            </td>
            <td class="aligned-quantity">
              <foodbank-number [value]="preparationItem.dispatchedQuantity" [decimals]="3" />
              <span class="unit">kg</span>
            </td>
            <td class="aligned-quantity">
              <ng-container *ngIf="(preparationItem.stock$ | async) as stock">
                <foodbank-number [value]="stock.quantity" [decimals]="3" />
                <span class="unit">kg</span>
              </ng-container>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
    <ng-template #noEstimatedValue>
      <div class="estimated">&nbsp;</div>
    </ng-template>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <span class="pi pi-print tab-icon"></span>
      Organization
    </ng-template>
    <ng-template pTemplate="content">
      <fead-preparation-organization-report *ngIf="preparationItemSearch() as preparationItemSearch"
        [preparationItemSearch]="preparationItemSearch"
       />
    </ng-template>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <span class="pi pi-print tab-icon"></span>
      Article
    </ng-template>
    <ng-template pTemplate="content">
      <fead-preparation-article-report *ngIf="preparationItemSearch() as preparationItemSearch"
        [preparationItemSearch]="preparationItemSearch"
       />
    </ng-template>
  </p-tabPanel>

  <p-tabPanel>
    <ng-template pTemplate="header">
      <span class="pi pi-print tab-icon"></span>
      Labels
    </ng-template>
    <ng-template pTemplate="content">
      <fead-preparation-labels-report *ngIf="preparationItemSearch() as preparationItemSearch"
        [preparationItemSearch]="preparationItemSearch"
       />
    </ng-template>
  </p-tabPanel>

</p-tabView>
