import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppLayoutModule} from '@components/layout/app.layout.module';
import {OAuthModule} from 'angular-oauth2-oidc';
import {environment} from '@environments/environment';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {MultiSelectModule} from 'primeng/multiselect';
import {ConfirmationService, MessageService, SharedModule} from 'primeng/api';
import {FormsModule} from '@angular/forms';
import {UserListComponent} from '@components/users/user-list.component';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {RippleModule} from 'primeng/ripple';
import {TableModule} from 'primeng/table';
import {TooltipModule} from 'primeng/tooltip';
import {UserTypeComponent} from '@components/user-type/user-type.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ArticleListComponent} from '@components/article/article-list.component';
import {ArticleEditComponent} from '@components/article/article-edit/article-edit.component';
import {DialogModule} from 'primeng/dialog';
import {CardModule} from 'primeng/card';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {FeadCampaignComponent} from '@components/fead-campaign/fead-campaign.component';
import {LanguageComponent} from '@components/language/language.component';
import {SupplierListComponent} from '@components/supplier/supplier-list.component';
import {SupplierCategoryComponent} from '@components/supplier-category/supplier-category.component';
import {SupplierCategorySelectionComponent} from '@components/supplier-category/supplier-category-selection.component';
import {FeadPlanningSummaryComponent} from '@components/fead-planning/summary/fead-planning-summary.component';
import {FeadPlanningArticleComponent} from '@components/fead-planning/article/fead-planning-article.component';
import {CheckboxModule} from 'primeng/checkbox';
import {FeadPlanningOrganizationComponent} from '@components/fead-planning/organization/fead-planning-organization.component';
import {FeadPlanningSummaryTableComponent} from '@components/fead-planning/summary/table/fead-planning-summary-table.component';
import {FeadPlanningOrganizationTableComponent} from '@components/fead-planning/organization/table/fead-planning-organization-table.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {HotkeyModule} from 'angular2-hotkeys';
import {WeekValidatorDirective} from '@directives/week.validator.directive';
import {FeadPlanningArticleTableComponent} from '@components/fead-planning/article/table/fead-planning-article-table.component';
import {FeadCampaignSelectionComponent} from '@components/fead-campaign/fead-campaign-selection/fead-campaign-selection.component';
import {FeadPlanningOrganizationTypeSelectionComponent} from '@components/fead-planning/article/fead-planning-organization-type-selection/fead-planning-organization-type-selection.component';
import {FeadPlanningQuantityCellEditorComponent} from '@components/fead-planning/fead-planning-quantity-cell-editor/fead-planning-quantity-cell-editor.component';
import {FeadPreparationComponent} from '@components/fead/preparation/single/fead-preparation.component';
import {FeadPreparationListComponent} from '@components/fead/preparation/list/fead-preparation-list.component';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FeadPreparationDetailsComponent} from '@components/fead/preparation/detail/fead-preparation-details.component';
import {FeadPreparationDetailsStandaloneComponent} from '@components/fead/preparation/detail/fead-preparation-details-standalone.component';
import {FeadPreparationNewComponent} from '@components/fead/preparation/new/fead-preparation-new.component';
import {FeadPreparationReportTableComponent} from '@components/fead/preparation/report/fead-preparation-report-table.component';
import {FeadPreparationLabelsComponent} from '@components/fead/preparation/report/labels/fead-preparation-labels.component';
import {NgxBarcode6Module} from 'ngx-barcode6';
import {FeadOrganizationReportComponent} from '@components/fead/preparation/report/organization/fead-organization-report.component';
import {TabViewModule} from 'primeng/tabview';
import {NumberComponent} from '@components/number/number.component';
import {FeadArticleReportComponent} from '@components/fead/preparation/report/article/fead-article-report.component';
import {WeekFormatDirective} from '@directives/week.format.directive';
import {FeadPlanningComponent} from '@components/fead/planning/fead-planning.component';
import {ReceptionListComponent} from '@components/reception/reception-list/reception-list.component';
import {SuppliersSelectionComponent} from '@components/supplier/selection/multiple/suppliers-selection.component';
import {CalendarModule} from 'primeng/calendar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ReceptionEditComponent} from '@components/reception/reception-edit/reception-edit.component';
import {ReceptionItemListComponent} from '@components/reception/reception-item-list/reception-item-list.component';
import {ReturnableListComponent} from '@components/returnable/returnable-list/returnable-list.component';
import {ReturnableComponent} from '@components/returnable/returnable.component';
import {ReceptionItemEditComponent} from '@components/reception/reception-item-edit/reception-item-edit.component';
import {InterWarehouseReceptionComponent} from '@components/inter-warehouse-reception/inter-warehouse-reception.component';
import {StockPrevisionListComponent} from '@components/stock-prevision/stock-prevision-list.component';
import {ReturnableSingleSelectionComponent} from '@components/returnable/selection/single/returnable-single-selection.component';
import {DividerModule} from 'primeng/divider';
import {PalletTypeSingleSelectionComponent} from '@components/pallet-type/selection/single/pallet-type-single-selection.component';
import {PalletTypeComponent} from '@components/pallet-type/pallet-type.component';
import {ReceptionPalletClusterTableComponent} from '@components/reception/reception-pallet-cluster-table/reception-pallet-cluster-table.component';
import {ReceptionItemComponent} from "@components/reception/reception-item/reception-item.component";
import {ReceptionLabelsComponent} from '@components/reception/reception-labels/reception-labels.component';
import {PalletLabelsComponent} from '@components/reception/reception-item-labels/pallet-labels.component';
import {PalletLabelComponent} from '@components/pallet/pallet-label/pallet-label.component';
import {NgxPrintModule} from 'ngx-print';
import {PanelModule} from "primeng/panel";
import {PreparationNewComponent} from '@components/preparation/new/preparation-new.component';
import {FieldsetModule} from 'primeng/fieldset';
import {DragDropModule} from 'primeng/dragdrop';
import {LocationComponent} from "@components/location/location.component";
import {LocationMultiSelectionComponent} from "@components/location/multi/location-multi-selection.component";
import {LocationSingleSelectionComponent} from "@components/location/single/location-single-selection.component";
import {AutoFocusModule} from "primeng/autofocus";
import {PalletMultiSelectionComponent} from "@components/pallet/selection/multi/pallet-multi-selection.component";
import {PreparationDistributionComponent} from "@components/preparation/distribution/preparation-distribution.component";
import {SetupPreparationTableComponent} from "@components/preparation/table/setup-preparation-table/setup-preparation-table.component";
import {MovementDialogComponent} from "@components/movement/new-movement-dialog/movement-dialog.component";
import {ToggleButtonModule} from "primeng/togglebutton";
import {DialogService} from "primeng/dynamicdialog";
import {ReceptionItemEditDatesValidatorDirective} from "@components/reception/reception-item-edit/reception-item-edit-dates-validator.directive";
import {BlockUIModule} from "primeng/blockui";
import {PreparationZoneComponent} from "@components/preparation-zone/preparation-zone.component";
import {PreparationZoneSingleSelectionComponent} from "@components/preparation-zone/selection/single/preparation-zone-single-selection.component";
import {StockPreparationTableComponent} from "@components/preparation/table/stock-preparation-table/stock-preparation-table.component";
import {ContextMenuModule} from "primeng/contextmenu";
import {ToastModule} from "primeng/toast";
import {ChartModule} from "primeng/chart";
import {PhoneNumberPipe} from "@util/phone-number-pipe";
import {UserComponent} from "@components/users/user.component";
import {WarehouseComponent} from "@components/warehouse/warehouse.component";
import {WarehouseSingleSelectionComponent} from "@components/warehouse/selection/single/warehouse-single-selection.component";
import {ArticleComponent} from "@components/article/article.component";
import {LanguageDirective} from "@directives/language.directive";
import {ChipModule} from "primeng/chip";
import {WarehouseMultipleSelectionComponent} from "@components/warehouse/selection/multiple/warehouse-multiple-selection.component";
import {OrganizationComponent} from "@components/organization/organization.component";
import {TableSizeComponent} from "@components/table-size/table-size.component";
import {ArticleCategorySelectionComponent} from "@components/article-category/selection/multi/article-category-selection.component";
import {ArticleMultiSelectionComponent} from "@components/article/selection/multi/article-multi-selection.component";
import {ArticleCategoryComponent} from "@components/article-category/article-category.component";
import {DatePickerComponent} from "@components/date/date-picker/date-picker.component";
import {SupplierSelectionComponent} from "@components/supplier/selection/single/supplier-selection.component";
import {StockListComponent} from "@components/stock/stock-list.component";
import {MovementListComponent} from "@components/movement/movement-list/movement-list.component";
import {ArticleSingleSelectionComponent} from "@components/article/selection/single/article-single-selection.component";
import {ArticleStorageConditionSelectionComponent} from "@components/article-storage-condition/article-storage-condition-selection.component";
import {ArticleStorageConditionComponent} from "@components/article-storage-condition/article-storage-condition.component";
import {PalletComponent} from "@components/pallet/pallet.component";
import {MovementTypeSingleSelectionComponent} from "@components/movement-type/selection/single/movement-type-single-selection.component";
import {OrganizationSingleSelectionComponent} from "@components/organization/selection/single/organization-single-selection.component";
import {PalletSingleSelectionComponent} from "@components/pallet/selection/single/pallet-single-selection.component";
import {SupplierComponent} from "@components/supplier/supplier.component";

@NgModule({
  declarations: [
    AppComponent,
    UserListComponent,
    ArticleListComponent,
    UserTypeComponent,
    ArticleEditComponent,
    FeadCampaignComponent,
    LanguageComponent,
    SupplierListComponent,
    SuppliersSelectionComponent,
    SupplierCategoryComponent,
    SupplierCategorySelectionComponent,
    FeadPlanningComponent,
    FeadPlanningSummaryComponent,
    FeadPlanningSummaryTableComponent,
    FeadPlanningOrganizationComponent,
    FeadPlanningOrganizationTableComponent,
    FeadPlanningArticleComponent,
    FeadPlanningArticleTableComponent,
    WeekValidatorDirective,
    WeekFormatDirective,
    FeadCampaignSelectionComponent,
    FeadPlanningOrganizationTypeSelectionComponent,
    FeadPlanningQuantityCellEditorComponent,
    FeadPreparationListComponent,
    FeadPreparationDetailsComponent,
    FeadPreparationReportTableComponent,
    FeadPreparationLabelsComponent,
    FeadOrganizationReportComponent,
    FeadArticleReportComponent,
    NumberComponent,
    FeadPreparationNewComponent,
    ReceptionListComponent,
    InterWarehouseReceptionComponent,
    PalletLabelComponent,
    PalletTypeComponent,
    PalletTypeSingleSelectionComponent,
    ReceptionEditComponent,
    ReceptionItemListComponent,
    ReturnableListComponent,
    ReturnableComponent,
    ReturnableSingleSelectionComponent,
    ReceptionItemEditComponent,
    ReceptionItemComponent,
    ReceptionPalletClusterTableComponent,
    ReceptionLabelsComponent,
    PalletLabelsComponent,
    PreparationNewComponent,
    PreparationDistributionComponent,
    FeadPreparationComponent,
    FeadPreparationDetailsStandaloneComponent,
    StockPrevisionListComponent,
    StockPreparationTableComponent,
    SetupPreparationTableComponent,
    LocationComponent,
    LocationMultiSelectionComponent,
    LocationSingleSelectionComponent,
    PalletMultiSelectionComponent,
    LocationSingleSelectionComponent,
    MovementDialogComponent,
    ReceptionItemEditDatesValidatorDirective,
    PreparationZoneComponent,
    PreparationZoneSingleSelectionComponent,
    PhoneNumberPipe,
  ],
  imports: [
    BrowserModule,
    FontAwesomeModule, /* usage: https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/icon-library.md */
    AppRoutingModule,
    AppLayoutModule,
    OAuthModule.forRoot({
      resourceServer: {
        customUrlValidation: url => url.startsWith(environment.apiUrl) && !url.startsWith(environment.apiUrl + '/config/oidc'),
        allowedUrls: [environment.apiUrl],
        sendAccessToken: true
      }
    }),
    MultiSelectModule,
    SharedModule,
    FormsModule,
    CommonModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    MultiSelectModule,
    RippleModule,
    TableModule,
    TooltipModule,
    OverlayPanelModule,
    DialogModule,
    CardModule,
    DropdownModule,
    CheckboxModule,
    ConfirmDialogModule,
    HotkeyModule.forRoot(),
    TriStateCheckboxModule,
    SelectButtonModule,
    NgxBarcode6Module,
    TabViewModule,
    CalendarModule,
    RadioButtonModule,
    DividerModule,
    NgxPrintModule,
    PanelModule,
    FieldsetModule,
    DragDropModule,
    AutoFocusModule,
    ToggleButtonModule,
    BlockUIModule,
    ContextMenuModule,
    ToastModule,
    ChartModule,
    UserComponent,
    WarehouseComponent,
    WarehouseSingleSelectionComponent,
    ArticleComponent,
    LanguageDirective,
    ChipModule,
    WarehouseMultipleSelectionComponent,
    OrganizationComponent,
    TableSizeComponent,
    ArticleCategorySelectionComponent,
    ArticleMultiSelectionComponent,
    ArticleCategoryComponent,
    DatePickerComponent,
    SupplierSelectionComponent,
    StockListComponent,
    MovementListComponent,
    ArticleSingleSelectionComponent,
    ArticleStorageConditionSelectionComponent,
    ArticleStorageConditionComponent,
    PalletComponent,
    MovementTypeSingleSelectionComponent,
    OrganizationSingleSelectionComponent,
    PalletSingleSelectionComponent,
    SupplierComponent,
  ],

  providers: [
    ConfirmationService,
    MessageService,
    DialogService,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
